import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Items } from './Items';
import { Link, useSearchParams } from 'react-router-dom';
import Pagination from '../../../../components/pagination/Pagination';
import axios from 'axios';
import ImageLazyLoading from '../../../../hooks/imageLazyLoading';
import AlterDate from '../../../../hooks/useAlterDate';

const NewsLetter = () => {
  const [pagingInfo, setPagingInfo] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = searchParams.get('pageNo') || 1;
  const [list, setList] = useState(null);

  useLayoutEffect(() => {
    const getList = async () => {
      const response = await axios.get(`/board/newsletter/list?pageSize=6&pageNo=${currentPage}`);
      if (response.data.boardList !== 0) {
        setList(response.data.boardList);
        setPagingInfo(response.data.boardParam);
      } else {
        setList([]);
      }
    };
    getList();
  }, [searchParams]);

  return (
    <>
      {list ? (
        <ul className="letterBox">
          {list?.map((it) => (
            <li key={it.idx}>
              <Link to={`detail/${it.idx}`}>
                <figure>
                  <img src={`https://backeg.kiwontech.com/file/download?idx=${it.mainImgIdx}`} alt="{it.title}" />
                </figure>
                <p>{it.title}</p>
              </Link>
            </li>
          ))}
        </ul>
      ) : (
        <div className="loading">
          <figure className="loadIcon">
            {/*<BiLoaderCircle />*/}
            <img src="/images/icon/spinner.gif" alt="" />
          </figure>
          <p>Loading...</p>
        </div>
      )}
      {list?.length !== 0 && pagingInfo ? <Pagination pagingInfo={pagingInfo} /> : null}
    </>
  );
};

export default NewsLetter;
