import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LogoImg } from './logoImg';
import { Controller, Scene } from 'react-scrollmagic';
import axios from 'axios';

const EgPlatform = () => {
  const tabRef = useRef([]);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isFixed, setIsFixed] = useState(false);
  const [selectedBtn, setSelectedBtn] = useState('');
  const [loading, setLoading] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 450) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
    if (tabRef.current[0].offsetTop - 300 <= window.scrollY) {
      setSelectedBtn('issue');
    }
    if (tabRef.current[1].offsetTop - 300 <= window.scrollY) {
      setSelectedBtn('system');
    }
    if (tabRef.current[2].offsetTop - 300 <= window.scrollY) {
      setSelectedBtn('features');
    }
    if (tabRef.current[3].offsetTop - 300 <= window.scrollY) {
      setSelectedBtn('benefits');
    }
    if (tabRef.current[4].offsetTop - 300 <= window.scrollY) {
      setSelectedBtn('form');
    }
    if (tabRef.current[5].offsetTop - 300 <= window.scrollY) {
      setSelectedBtn('company');
    }
    if (tabRef.current[0].offsetTop - 300 > window.scrollY) {
      setSelectedBtn('');
    }
  };

  const moveToArea = (index) => {
    tabRef?.current[index]?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      // inline: 'start',
    });
  };
  const moveToAreaStart = (idx) => {
    tabRef?.current[idx]?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      // inline: 'start',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isFixed]);

  useEffect(() => {
    if (pathname === '/eg-platform') {
      window.scrollTo(0, 0);
    }
    if (pathname === '/eg-platform/security-issue') {
      moveToAreaStart(0);
      setSelectedBtn('issue');
    }
    if (pathname === '/eg-platform/functional-system') {
      moveToArea(1);
      setSelectedBtn('system');
    }
    if (pathname === '/eg-platform/features') {
      moveToArea(2);
      setSelectedBtn('features');
    }
    if (pathname === '/eg-platform/support-benefits') {
      moveToArea(3);
      setSelectedBtn('benefits');
    }
    if (pathname === '/eg-platform/introduction-form') {
      moveToArea(4);
      setSelectedBtn('form');
    }
    if (pathname === '/eg-platform/introduction-company') {
      moveToArea(5);
      setSelectedBtn('company');
    }
  }, [pathname]);

  const onClickDownload = useCallback(async () => {
    if (loading) {
      return false;
    }
    try {
      await axios({
        method: 'GET',
        url: `/file/download/brochure`,
        responseType: 'blob',
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
        const link = document.createElement('a');
        link.href = url;
        const fileName = response.headers['content-disposition']?.split(/[=,;]/)[2];
        link.setAttribute('download', decodeURI(fileName?.replace(/\"/gi, '')));
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {
      alert('서버에러가 발생했습니다.');
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <>
      <section className="egPlatform">
        <Controller>
          <Scene triggerElement="#triggerPoint00" classToggle="active_mv">
            <div className="w1200" id="triggerPoint00">
              <h2>
                분리된 보안이 아닌, <span className="bold">모든 영역</span>에
                <br />
                <span className="bold"> 전사적으로 대응</span>하는
                <br className="w400" /> <span className="blue">EG-Platform</span>
              </h2>
              <div className="suggest">
                <p>
                  발전되는 이메일 공격에 대응하기 위해서는 영역별 보안만으로는 부족합니다.
                  <br />
                  EG-Platform은 세계 유일, "<span className="bold">이메일 보호</span>"라는 목적 아래 <span className="bold">전 영역에 일관된 보안을 제공</span>
                  합니다.
                </p>
              </div>
            </div>
          </Scene>
        </Controller>
      </section>
      <section
        className="securityIssue"
        ref={(element) => {
          tabRef.current[0] = element;
        }}
      >
        <div className={isFixed ? 'menuPart fixed' : 'menuPart'}>
          <ul className="tabMenu w800">
            <li className={selectedBtn === 'issue' ? 'active' : ''}>
              <button
                type="button"
                onClick={() => {
                  moveToAreaStart(0);
                }}
              >
                보안이슈
              </button>
            </li>
            <li className={selectedBtn === 'system' ? 'active' : ''}>
              <button
                type="button"
                onClick={() => {
                  moveToArea(1);
                }}
              >
                기능체계
              </button>
            </li>
            <li className={selectedBtn === 'features' ? 'active' : ''}>
              <button
                type="button"
                onClick={() => {
                  moveToArea(2);
                }}
              >
                특장점
              </button>
            </li>
            <li className={selectedBtn === 'benefits' ? 'active' : ''}>
              <button
                type="button"
                onClick={() => {
                  moveToArea(3);
                }}
              >
                지원혜택
              </button>
            </li>
            <li className={selectedBtn === 'form' ? 'w450 active' : 'w450'}>
              <button
                type="button"
                onClick={() => {
                  moveToArea(4);
                }}
              >
                도입형태
              </button>
            </li>
            <li className={selectedBtn === 'company' ? 'w450 active' : 'w450'}>
              <button
                type="button"
                onClick={() => {
                  moveToArea(5);
                }}
              >
                도입고객사
              </button>
            </li>
            <li className={selectedBtn === 'form' || selectedBtn === 'company' ? 'w400 active' : 'w400'}>
              <button
                type="button"
                onClick={() => {
                  moveToArea(4);
                }}
              >
                도입
              </button>
            </li>
          </ul>
        </div>
        <div className="w1200">
          <p>세계유일, All-In-One 이메일 통합 보안 플랫폼</p>
          <h2>
            수신부터 발신까지, <br className="w575" />
            EG-Platform 하나로!
          </h2>
          <div className="flex">
            <div>
              <h3 className="boxTit">수신메일 보안이슈</h3>
              <ul>
                <li>
                  <p>대량 스팸 메일 공격</p>
                </li>
                <li>
                  <p>불법 릴레이 서버 사용</p>
                </li>
                <li>
                  <p>바이러스, 랜섬웨어 등 악성코드 공격</p>
                </li>
                <li>
                  <p>개인정보 탈취 피싱 사이트 공격</p>
                </li>
                <li>
                  <p>헤더 위·변조 및 유사 도메인 사칭 메일</p>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="boxTit">발신메일 보안이슈</h3>
              <ul>
                <li>
                  <p>악성 메일 회신</p>
                </li>
                <li>
                  <p>바이러스 URL 공유</p>
                </li>
                <li>
                  <p>기업 내부 정보 유출 및 오발송 사고</p>
                </li>
                <li>
                  <p>대용량 파일 사후관리 문제</p>
                </li>
                <li>
                  <p>망분리 환경에서 대용량 첨부파일 발송 문제</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section
        className="functionalSystem"
        ref={(element) => {
          tabRef.current[1] = element;
        }}
      >
        <div className="container">
          <h2>
            이메일의 전 구간을 보호하는 <br className="w800" />"<span className="blue">EG-Platform</span>"
          </h2>
          <p>
            이메일이 수신되는 순간부터 발신되는 순간까지,
            <br />전 구간을 체계적으로 보호하는 <span className="bold">All-In-One 이메일 통합 보안 플랫폼</span>입니다.
          </p>
          {/*          <div className="image">
            <figure>
              <img src="/images/sub_egplatform_01.png" alt="기능체계 설명 이미지" />
            </figure>
          </div>*/}
        </div>
      </section>
      <section
        className="features"
        ref={(element) => {
          tabRef.current[2] = element;
        }}
      >
        <div className="w1200">
          <h2>
            왜 <span className="blue">EG-Platform</span> 일까요?
          </h2>
          <p>발전하는 이메일 공격에 맞춤형으로 대응하는 새로운 경험을 제공합니다.</p>
          <ul className="flex">
            <li>
              <div>
                <h3>
                  기업 맞춤형
                  <br className="w1070" />
                  보안 환경 <br className="w1160" /> 구축
                </h3>
                <p>
                  기업에 수신되는 모든 메일을&nbsp;
                  <br className="w1070" />
                  학습, 분석해 기업 특성에&nbsp;
                  <br className="w1070" />
                  맞는 맞춤형 보안 환경 구축
                </p>
              </div>
            </li>
            <li>
              <div>
                <h3>
                  행위 분석으로
                  <br />더 정밀한 악성 메일 <br className="w1160" />
                  차단
                </h3>
                <p>
                  행위 기반 분석을 통해&nbsp;
                  <br className="w1070" />
                  지능화된 피싱, 신종 악성코드&nbsp;
                  <br className="w1070" />
                  검출 및 선제 대응 가능
                </p>
              </div>
            </li>
            <li>
              <div>
                <h3>
                  컨설팅부터 운영,
                  <br />
                  유지보수까지 원스톱 지원
                </h3>
                <p>
                  수 · 발신 전 영역 관리로&nbsp;
                  <br className="w1070" />
                  구간에 구애 받지 않는
                  <br className="w1070" />
                  빠르고 정확한 기술지원
                </p>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <section
        className="introMap"
        ref={(element) => {
          tabRef.current[3] = element;
        }}
      >
        <div className="w1200">
          <h2>
            컨설팅부터 구축, 운영 및 유지보수까지 <br className="w560" />
            <span className="bold">한 번에!</span>
          </h2>
          <p>빈틈 없는 이메일 보안을 위해 기원테크가 지원해 드립니다.</p>
          <figure>
            <img src="/images/sub_eglpatform_map.png" alt="컨설팅부터 구축, 운영 및 유지보수까지 한 번에" className="w1920" />
            <img src="/images/sub_eglpatform_map800.png" alt="컨설팅부터 구축, 운영 및 유지보수까지 한 번에" className="w800" />
            <img src="/images/sub_eglpatform_map400.png" alt="컨설팅부터 구축, 운영 및 유지보수까지 한 번에" className="w400" />
          </figure>
        </div>
      </section>
      <section
        className="egService"
        ref={(element) => {
          tabRef.current[4] = element;
        }}
      >
        <div className="w1200">
          <p>
            어떤 환경에서도 편리하게 <br className="dis-n_m" /> 사용 가능한 이지플랫폼 서비스
          </p>
          <h2>
            기업 환경에 적합한 <span className="bold">도입 형태</span> 선택 가능
          </h2>
          <div className="gridContainer">
            <div className="item">
              <h3>Appliance</h3>
              <p>
                기업 내부에 소프트웨어가 설치 된 서버를 구축하여&nbsp;
                <br className="w800" />
                메일 서버와 직접 연결하는 형태
              </p>
            </div>
            <div className="item">
              <h3>License</h3>
              <p>
                기업 내부에 구축된 서버에
                <br className="w740" />
                EG-Platform 소프트웨어를 설치하는 형태
              </p>
            </div>
            <div className="item">
              <h3>SaaS</h3>
              <p>
                가상 서버에 EG-Platform 소프트웨어를 설치하는 형태&nbsp;
                <br className="w650" />
                <span className="small">*ReceiveGUARD 검사 보조 장비(CUBE)는 물리 서버 필요</span>
              </p>
            </div>
            <div className="item">
              <h3>Cloud</h3>
              <p>
                기원테크 자체 운영
                <br />
                IDC를 활용한 클라우드형 서비스
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        className="enterprise"
        ref={(element) => {
          tabRef.current[5] = element;
        }}
      >
        <div className="w1300">
          <h2>
            <span className="blue">EG-Platform</span> 도입 기업들
          </h2>
          <p>EG-Platform은 다양한 분야 고객들의 이메일 보안을 책임지며 함께 성장하고 있습니다.</p>
          <div className="gridContainer">
            {LogoImg.map((it) => (
              <div className="item" key={it.id}>
                <figure>
                  <img src={it.src} alt="" />
                </figure>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="inquiry">
        <div className="w1200">
          <h2>
            <span className="blue">EG-Platform</span>에 대해 궁금한 점이 있으신가요?
          </h2>
          <ul className="flex">
            <li>
              <h3>프로모션 브로슈어</h3>
              <p>
                이지플랫폼에 대한 <br className="w1065" />
                정보를 한 눈에!
              </p>
              <button type="button" onClick={onClickDownload}>
                다운로드
              </button>
            </li>
            <li>
              <h3>EG-Platform</h3>
              <p>
                더 자세한 정보를
                <br className="w1065" /> 원하시나요?
              </p>
              <button onClick={() => navigate('/customer/product-inquiry/contact-us')}>제품문의</button>
            </li>
            <li>
              <h3>공식 파트너사</h3>
              <p>
                기원테크의 파트너사를
                <br className="w1065" /> 모집중입니다.
              </p>
              <button onClick={() => navigate('/customer/partnership-inquiry/contact-us')}>제휴문의</button>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default EgPlatform;
