import React, { useEffect, useRef, useState } from 'react';

const ImageLazyLoading = ({ src }) => {
  const imgRef = useRef(null); //이미지 태그 요소
  const observer = useRef(); // intersectionOberserver 변수
  const [isLoad, setIsLoad] = useState(false); //실제 화면에 보여지고 있는지

  useEffect(() => {
    observer.current = new IntersectionObserver(intersectionOberserver); // 인스턴스 생성
    imgRef.current && observer.current.observe(imgRef.current); // 이미지 태그 관찰 시작
  }, []);

  const intersectionOberserver = (entries, io) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // 관찰되고 있는 entry가 보여지게 되면
        io.unobserve(entry.target); // 관찰 종료
        setIsLoad(true); // 로딩 체크
      }
    });
  };
  // 화면에 보여지기 전이라면 NoImage, 화면에 보여지고 있다면 src에 해당하는 이미지
  return <img ref={imgRef} src={isLoad ? src : '/images/lazyImage.png'} />;
};

export default ImageLazyLoading;
