import React from 'react';

const PersonalInformation = ({ privacyChk, setPrivacyChk }) => {
  return (
    <div className="personalInfo">
      <h3>개인정보 수집 및 이용안내</h3>
      <p className="notice">
        ※ ㈜기원테크 이하 “회사”는 개인정보보호법 및 정보통신망법에 의거 개인정보 수집 시 아래와 같은 사항을 알려드리며, 본인의 동의를 받고 있으니 동의여부에
        따른 서명을 해 주시기 바랍니다.
      </p>
      <div className="useGuide">
        <div className="content">
          <p className="terms_text">
            "㈜기원테크"에서 운영하는 기원테크 이지플랫폼 홈페이지(http://www.secu-email.com/) 는 원활한 고객상담, 각종 고객지원 등 기본적인 서비스 제공을
            위하여 필수적인 항목에 대해서만 개인정보를 수집하고 있습니다. 이는 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등
            정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보취급방침을 정하여 이용자 권익 보호에
            최선을 다하고 있습니다.
          </p>

          <p className="terms_text_number">1.수집하는 개인정보 항목</p>

          <p className="terms_text_underText">1) 수집 항목</p>

          <p className="terms_text_list">
            “㈜기원테크”는 고객의 영업문의 서비스 제공을 위하여 아래와 같은 개인정보를 수집하고 있으며, 필수항목과 선택항목으로 구분되어 있습니다.
          </p>

          <p className="terms_subTitle">① 필수항목 : 회사명, 성명, 전화번호, 이메일</p>

          <p className="terms_text_underText">2) 개인정보 수집방법</p>

          <p className="terms_subTitle mb30">① 홈페이지 내 상담문의 화면</p>

          <p className="terms_text_number">2. 개인정보의 수집 및 이용목적</p>

          <p className="terms_text_underText">
            “㈜기원테크”는 수집한 개인정보를 다음의 목적을 위해 활용합니다. 이용자가 제공한 모든 정보는 하기 목적에 필요한 용도 이외로는 사용되지 않으며 이용
            목적이 변경될 시에는 사전 동의를 구할 것입니다.
          </p>

          <p className="terms_text_list">1) 서비스 이용에 따른 고객식별</p>

          <p className="terms_text_list">2) 정보전달에 따른 필요</p>

          <p className="terms_text_list mb30">3) 업체의 특성을 파악하고 좀더 개연성 있는 정보의 전달</p>

          <p className="terms_text_number">3. 개인정보의 보유 및 이용기간</p>

          <p className="terms_text_underText">
            ㈜기원테크는 수집 및 이용목적이 달성되거나 보유 및 이용기간이 종료한 경우 해당 개인정보를 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의
            이유로 명시한 기간 동안 보존합니다. 그리고 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이 관계법령에서 정한 일정한 기간 동안
            회원정보를 보관합니다.
          </p>

          <p className="terms_text_list">가. 관련법령에 의한 정보보유 사유</p>

          <p className="terms_subTitle mb20">
            상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 ㈜기원테크 회원은 서비스 철회 이후에도 관계법령에서
            정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 ㈜기원테크는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.
          </p>

          <p className="terms_text_listTitle">- 계약 또는 청약철회 등에 관한 기록</p>
          <p className="terms_text_list">보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</p>
          <p className="terms_text_list mb20">보존 기간 : 5년</p>

          <p className="terms_text_listTitle">- 대금결제 및 재화 등의 공급에 관한 기록</p>
          <p className="terms_text_list">보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</p>
          <p className="terms_text_list mb20">보존 기간 : 5년</p>

          <p className="terms_text_listTitle">- 소비자의 불만 또는 분쟁처리에 관한 기록</p>
          <p className="terms_text_list">보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</p>
          <p className="terms_text_list mb20">보존 기간 : 3년</p>

          <p className="terms_text_listTitle">- 본인확인에 관한 기록</p>
          <p className="terms_text_list">보존 이유 : 정보통신 이용촉진 및 정보보호 등에 관한 법률</p>
          <p className="terms_text_list mb20">보존 기간 : 6개월</p>

          <p className="terms_text_listTitle">- 방문에 관한 기록</p>
          <p className="terms_text_list mb30">보존 기간 : 3개월</p>

          <p className="terms_text_number">4. 개인정보의 파기절차 및 방법</p>

          <p className="terms_text_underText mb10">
            ㈜기원테크는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
          </p>

          <p className="terms_text_listTitle mb10">파기절차</p>
          <p className="terms_text_list">
            - 이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한
            정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기되어 집니다.
          </p>

          <p className="terms_text_list mb30">- 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.</p>

          <p className="terms_text_listTitle mb10">파기방법</p>
          <p className="terms_text_list">- 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.</p>
          <p className="terms_text_list mb30">- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</p>

          <p className="terms_text_number">5. 개인정보 제공</p>

          <p className="terms_text_underText">
            ㈜기원테크는 이용자들의 개인정보를 "2. 개인정보의 수집목적 및 이용목적"에서 고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이는 동 범위를
            초과하여 이용하거나 원칙적으로 이용자의 개인정보를 외부에 제공하지 않습니다. 단, 다음의 경우에는 주의를 기울여 개인정보를 이용 및 제공할 수
            있습니다.
          </p>

          <p className="terms_text_underText">- 이용자들이 사전에 공개에 동의한 경우</p>

          <p className="terms_text_underText mb30">- 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우</p>

          <p className="terms_text_number">6. 수집한 개인정보의 위탁</p>

          <p className="terms_text_underText mb30">
            ㈜기원테크는 이용자의 동의 없이 이용자의 개인정보 취급을 외부 업체에 위탁하지 않습니다. 향후 그러한 필요가 생길 경우, 위탁 대상자와 위탁 업무 내용을
            이용자에게 통지하고 필요한 경우 사전 동의를 받습니다.
          </p>

          <p className="terms_text_number">7. 이용자 및 법정대리인의 권리와 그 행사방법</p>

          <p className="terms_text_underText">
            이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수도
            있습니다. 이용자 혹은 만 14세 미만 아동의 개인정보 조회/수정을 위해서는 '개인정보변경'(또는 '회원정보수정' 등)을 가입해지(동의철회)를 위해서는
            "회원탈퇴"를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로
            연락하시면 지체 없이 조치하겠습니다.
          </p>

          <p className="terms_text_underText">
            이용자의 개인정보의 오류에 대한 정정을 요청한 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를
            제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.
          </p>

          <p className="terms_text_underText mb30">
            ㈜기원테크는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 "개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의
            용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
          </p>

          <p className="terms_text_number">8. 개인정보의 기술적, 관리적 보호 대책</p>

          <p className="terms_text_underText">
            ㈜기원테크는 이용자의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안정성 확보를 위하여 다음과 같은 기술적,
            관리적 대책을 강구하고 있습니다.
          </p>

          <p className="terms_text_listTitle">1) 해킹 등에 대비한 기술적 대책</p>

          <p className="terms_subTitle">
            ㈜기원테크는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 침입 차단장치 이용 및 침입탐지시스템을
            설치하였으며, 백신 프로그램을 주기적으로 업데이트하며 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 이를 적용함으로써 개인정보가 침해되는 것을
            방지하고 있습니다.
          </p>

          <p className="terms_text_listTitle">2) 개인정보처리시스템 접근 제한</p>

          <p className="terms_subTitle">
            ㈜기원테크는 개인정보를 처리할 수 있도록 체계적으로 구성한 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소 등에 관한 기준을 수립하고 기타
            개인정보에 대한 접근통제를 위해 필요한 조치를 다하고 있습니다.
          </p>

          <p className="terms_text_listTitle">3) 개인정보 취급 직원의 교육</p>

          <p className="terms_subTitle mb30">
            개인정보관련 취급 직원은 담당자에 한정시켜 최소화 하고 새로운 보안기술의 습득 및 개인정보보호 의무에 관해 정기적인 교육을 실시하며 별도의 비밀번호를
            부여하여 접근 권한을 관리하는 등 관리적 대책을 시행하고 있습니다.
          </p>

          <p className="terms_text_number">9. 개인정보에 관한 민원서비스</p>

          <p className="terms_text_underText mb10">
            ㈜기원테크는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 개인정보관리책임자를 지정하고 있습니다.
            <br />
            이용자는 ㈜기원테크의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자로 신고하실 수 있습니다. ㈜기원테크는 이용자들의
            신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
          </p>

          <p className="terms_text_listTitle">* 개인정보 관리책임자</p>

          <p className="terms_text_list">- 담당자 : 손희영 실장</p>
          <p className="terms_text_list">- 전화 : 02-6012-7406</p>
          <p className="terms_text_list">- 이메일 : 315@60127406.com</p>

          <p className="terms_subTitle mb6">기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.</p>

          <p className="terms_text_list">1. 개인분쟁조정위원회 (1336)</p>
          <p className="terms_text_list">2. 정보보호마크인증위원회 (02-580-0533~4)</p>
          <p className="terms_text_list">3. 대검찰청 인터넷범죄수사센터 (02-3480-3600)</p>
          <p className="terms_text_list mb30">4. 경찰청 사이버테러대응센터 (02-392-0330)</p>

          <p className="terms_text_number">10. 부칙</p>

          <p className="terms_text_underText">
            이 개인정보취급방침은 2010년 2월 1일부터 적용되며, 법령. 정책 또는 보안기술의 변경에 따라 내용의 추가. 삭제 및 수정이 있을 시에는 변경사항의
            시행일의 7일 전부터 ㈜기원테크 사이트의 공지사항을 통하여 고지할 것 입니다.
          </p>

          <p className="terms_text_list">o 개인정보취급방침 시행일자 : 2015년 2월 1일</p>
          <p className="terms_text_list">o 개인정보취급방침 공고일자 : 2015년 2월 1일</p>
        </div>
      </div>
      <div className="btn_chk">
        <label className="checkBox">
          <input
            type="checkbox"
            name="privacyChk"
            title="개인정보 수집 및 이용"
            value={privacyChk}
            className="check_box"
            onChange={() => {
              setPrivacyChk(!privacyChk);
            }}
            checked={privacyChk}
          />
          <span className="checkmark"></span>
          <span className="agree">개인정보 수집 및 이용에 동의합니다.</span>
        </label>
      </div>
    </div>
  );
};

export default PersonalInformation;
