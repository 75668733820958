import React, { useEffect, useRef, useState } from 'react';
import { Controller, Scene } from 'react-scrollmagic';

const Certification = () => {
  const tabRef = useRef([]);
  const [isFixed, setIsFixed] = useState(false);
  const [selectedBtn, setSelectedBtn] = useState('');

  const handleScroll = () => {
    if (window.scrollY > 400) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
    if (tabRef.current[0].offsetTop - 300 <= window.scrollY) {
      setSelectedBtn('cc');
    }
    if (tabRef.current[1].offsetTop - 300 <= window.scrollY) {
      setSelectedBtn('acquired');
    }
    if (tabRef.current[2].offsetTop - 300 <= window.scrollY) {
      setSelectedBtn('effected');
    }
    if (tabRef.current[0].offsetTop - 300 > window.scrollY) {
      setSelectedBtn('');
    }
  };

  // const moveToArea = (index) => {
  //   tabRef?.current[index].scrollIntoView({
  //     behavior: 'smooth',
  //     block: 'start',
  //   });
  // };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isFixed]);

  return (
    <>
      <section className="inboundSecu cc">
        <Controller>
          <Scene triggerElement="#triggerPoint00" classToggle="active_mv">
            <div className="container" id="triggerPoint00">
              <p className="engTitle">Technical Certification</p>
              <h2>CC인증</h2>
              <p>
                수신 메일 보안 솔루션 <br className="w960" />
                ReceiveGUARD(리시브가드) V1.1 에 대한
                <br />
                CC인증(EAL2)을 기원테크가 획득했습니다.
              </p>
            </div>
          </Scene>
        </Controller>
      </section>
      <section
        className="ReceiveGuard"
        ref={(element) => {
          tabRef.current[0] = element;
        }}
      >
        <div className={isFixed ? 'menuPart fixed' : 'menuPart'}>
          <ul className="tabMenu w350">
            <li className={selectedBtn === 'cc' ? 'active' : ''}>
              <button
                type="button"
                onClick={() => {
                  tabRef?.current[0].scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  });
                }}
              >
                CC인증
              </button>
            </li>
            <li className={selectedBtn === 'acquired' ? 'active' : ''}>
              <button
                type="button"
                onClick={() => {
                  tabRef?.current[1].scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  });
                }}
              >
                인증획득
              </button>
            </li>
            <li className={selectedBtn === 'effected' ? 'active' : ''}>
              <button
                type="button"
                onClick={() => {
                  tabRef?.current[2].scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  });
                }}
              >
                기대효과
              </button>
            </li>
          </ul>
        </div>
        <div className="w1200">
          <div className="flex">
            <figure>
              <img src="/images/sub_cc_receiveguard.png" alt="ReceiveGUARD(리시브가드) V1.1 이미지" />
            </figure>
            <div className="description">
              <p>수신 메인 보안 솔루션</p>
              <h2>Receive GUARD v1.1</h2>
              <p className="subTit">차세대 메일 보안 방화벽</p>
              <p>
                ReceiveGUARD(리시브가드) V1.1은 자체 개발 AI와
                <br />
                이메일 보안의 결합으로 세계 유일 지능형 사기 메일 선제 대응 솔루션입니다.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        className="acquired"
        ref={(element) => {
          tabRef.current[1] = element;
        }}
      >
        <div className="w1200">
          <h2>
            <span className="bold">ReceiveGUARD V1.1</span>은 보안 제품에 대한 국제공통평가기준을
            <br />
            만족하며, 정보 및 공공기관이 정보보안 제품을 도입할 때 필수적으로 요구되는
            <br />
            <span className="bold"> CC인증(EAL2)을 획득</span>하였습니다.
          </h2>
        </div>
      </section>
      <section
        className="expectedEffect"
        ref={(element) => {
          tabRef.current[2] = element;
        }}
      >
        <div className="w1200">
          <h2>기대효과</h2>
          <p>CC인증이 완료된 ReceiveGUARD V1.1을 도입했을 경우 기대할 수 있는 효과입니다.</p>
          <div className="gridContainer">
            <div className="item">
              <div>
                <h3>유사 도메인 검출</h3>
                <p>
                  거래처 메일 주소와 유사한 주소로 <br /> 변경하여 보내는 사기 메일에 대응합니다.
                </p>
              </div>
            </div>
            <div className="item">
              <div>
                <h3>발송 경로 추적</h3>
                <p>
                  기존과 달리 변경된 메일 주소로 메일 수신 시 <br />
                  사용자에게 변경된 발송 경로를 안내합니다.
                </p>
              </div>
            </div>
            <div className="item">
              <div>
                <h3>바이러스 검출</h3>
                <p>
                  사용자에게 피해를 줄 수 있는
                  <br /> 바이러스 메일을 검출하고 차단합니다.
                </p>
              </div>
            </div>
            <div className="item">
              <div>
                <h3>발신 주소 위·변조 검사</h3>
                <p>
                  발신 주소의 유효성을 체크해 답장
                  <br /> 클릭 시 변경되는 메일 주소를 검출합니다.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sec04">
        <div className="w1320"></div>
      </section>
      <section className="ccRecommend"></section>
    </>
  );
};

export default Certification;
