export const functionData = [
  {
    id: 1,
    tabTitle: 'EML 컨텐츠 재조합',
    content: [
      {
        id: 1,
        conTitle: 'EML 컨텐츠 재조합',
        contDes: '망연계 네트워크 환경에서도 외부 사용자와\n' + '이메일 컨텐츠를 원활하게 주고받을 수 있는\n' + 'EML 재조합 기술',
        src1: '/images/sub_eglinker_tab01.png',
        src1_m: '/images/sub_eglinker_tab01_m.png',
        src1_mm: '/images/sub_eglinker_tab01_mm.png',
        src2: '/images/sub_eglinker_tab05.png',
        src2_m: '/images/sub_eglinker_tab05_m.png',
      },
    ],
  },
  {
    id: 2,
    tabTitle: '일반·대용량 첨부파일 관리',
    content: [
      {
        id: 2,
        conTitle: '일반·대용량\n' + '첨부파일 관리',
        contDes:
          '첨부파일을 EG-Linker 다운로드 링크로\n' + '변환하여 사용자가 직접 다운로드 제한,\n' + '‘다운로드 IP/일시’ 정보 제공을 통한,\n' + '모니터링 가능',
        src1: '/images/sub_eglinker_tab02.png',
        src1_m: '/images/sub_eglinker_tab02_m.png',
        src1_mm: '/images/sub_eglinker_tab02_mm.png',
      },
    ],
  },
  {
    id: 3,
    tabTitle: '개인 계정별 분리 운영',
    content: [
      {
        id: 3,
        conTitle: '개인 계정별 분리 운영',
        contDes: '동일 도메인에 대해 그룹사 및 개인별\n' + '분리 운영 가능 및 기업 조직도 기반 체계적인\n' + '조직도 관리 시스템 구축',
        src1: '/images/sub_eglinker_tab03.png',
        src1_m: '/images/sub_eglinker_tab03_m.png',
        src1_mm: '/images/sub_eglinker_tab03_mm.png',
      },
    ],
  },
  {
    id: 4,
    tabTitle: '외부 DRM 및 AIP 연동',
    content: [
      {
        id: 4,
        conTitle: '외부 DRM 및\n' + 'AIP 연동',
        contDes: '첨부파일 암호화 시스템으로 안전하게\n' + '보호하는 동시에 분석 및 이력 관리 기능까지\n' + '한번에 체크!',
        src1: '/images/sub_eglinker_tab04.png',
        src1_m: '/images/sub_eglinker_tab04_m.png',
        src1_mm: '/images/sub_eglinker_tab04_mm.png',
      },
    ],
  },
];
