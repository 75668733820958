import React from 'react';
import './style.scss';

const EventPopUp = ({ onClickPop, onChangeToday }) => {
  return (
    <div className="popup_wrap_event">
      <img src="/images/popup_240911.png" alt="설 팝업" className="pc" />
      <img src="/images/popup_240911_m.png" alt="설 팝업" className="mb" />
      <div className="b-eventClose">
        <label>
          <input type="checkbox" value={false} onChange={(e) => onChangeToday(e.currentTarget.checked)} />
          오늘 하루 동안 열지 않음
        </label>
        <button type="button" className="b-eventClose__btn" onClick={onClickPop}>
          <span>닫기</span>
        </button>
      </div>
    </div>
  );
};

export default EventPopUp;
