import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import PersoanlInfo from './persoanlInfo';
import { BsXLg } from 'react-icons/bs';

const Modal = ({ closeModal, productTarget, setDownloadConfirm }) => {
  const inputRef = useRef([]);
  const emailForm = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,5}$/i;
  const rgNumWithCommas = (str) => {
    return str.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  };
  const inputValue = {
    productTarget: productTarget,
    company: '',
    employeeCount: '',
    managerName: '',
    manageContact: '',
    manageEmail: '',
    manage: '',
  };
  const [values, setValues] = useState(inputValue);
  const [loading, setLoading] = useState(false);
  const [privacyChk, setPrivacyChk] = useState(false);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (loading) {
        return false;
      }
      for (let i = 0; i < inputRef.current.length; i++) {
        if (inputRef.current[i].value === '' || !inputRef.current[i].value.trim()) {
          alert(inputRef.current[i].title + '을(를) 입력해주세요.');
          inputRef.current[i].focus();
          return false;
        }
      }
      if (!emailForm.test(values.manageEmail)) {
        alert('이메일 양식을 확인해주세요.');
        inputRef.current[5].focus();
        return false;
      }
      if (privacyChk === false) {
        alert('개인정보 수집 및 이용을 동의해주세요');
        return false;
      }

      try {
        setLoading(true);
        axios
          .post(
            `/download`,
            {
              ...values,
              employeeCount: values.employeeCount,
              privacyChk: 'Y',
              managerName: `${values.managerName}/${values.manage}`,
              manageContact: values.manageContact.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'),
            },
            {
              headers: {
                'Content-Type': `application/x-www-form-urlencoded`,
              },
            },
          )
          .then((res) => {
            setValues({ ...inputValue });
            setPrivacyChk(false);
          });
      } catch (error) {
        alert('오류가 발생했습니다. 관리자에게 연락 부탁드립니다.');
      } finally {
        setLoading(false);
        setDownloadConfirm(true);
        closeModal();
      }
    },
    [values, privacyChk],
  );

  useEffect(() => {
    document.body.style.cssText = `
    position: fixed; 
    top: -${window.scrollY}px;
    overflow-y: scroll;
    width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    };
  }, []);
  return (
    <form onSubmit={onSubmit}>
      <div className="modalWrap" onClick={closeModal}>
        <div className="modalBody" onClick={(e) => e.stopPropagation()}>
          <div className="title">
            <h2>
              {productTarget.indexOf('EG') === 0 && 'EG-Platform'}
              {productTarget.indexOf('EL') === 0 && 'EG-Linker'}
              &nbsp;
              <span className="blue">
                {productTarget.indexOf('P') === 3 && '제안서'}
                {productTarget.indexOf('B') === 3 && '브로슈어'}
              </span>
            </h2>
            <p>
              자료 다운로드를 위해 아래 정보를 입력하여 주시기 바랍니다.
              <br />
              담당자 확인 후 입력하신 이메일로 보내 드립니다.
            </p>
          </div>
          <div className="scroll">
            <div className="w620">
              <div className="inputBox">
                <label>
                  <span className="inputTit">기업명</span>
                  <input
                    type="text"
                    name="company"
                    title="기업명"
                    ref={(el) => (inputRef.current[0] = el)}
                    value={values.company}
                    onChange={handleChange}
                    placeholder="기업명을 입력해 주세요."
                  />
                </label>
                <label>
                  <span className="inputTit">회사 규모</span>
                  <input
                    type="text"
                    name="employeeCount"
                    title="회사 규모"
                    ref={(el) => (inputRef.current[1] = el)}
                    value={values.employeeCount}
                    onChange={handleChange}
                    placeholder="직원 수를 입력해 주세요"
                  />
                </label>
                <label>
                  <span className="inputTit">담당자명</span>
                  <input
                    type="text"
                    name="managerName"
                    title="담당자명"
                    ref={(el) => (inputRef.current[2] = el)}
                    value={values.managerName}
                    onChange={handleChange}
                    placeholder="담당자분의 성함을 입력해 주세요."
                  />
                </label>
                <label>
                  <span className="inputTit">직함</span>
                  <input
                    type="text"
                    name="manage"
                    title="직함"
                    ref={(el) => (inputRef.current[3] = el)}
                    value={values.manage}
                    onChange={handleChange}
                    placeholder="담당자분의 직함을 입력해 주세요."
                  />
                </label>
                <label>
                  <span className="inputTit">연락처</span>
                  <input
                    type="text"
                    name="manageContact"
                    title="연락처"
                    ref={(el) => (inputRef.current[4] = el)}
                    value={rgNumWithCommas(values.manageContact.replace(/[^0-9]/g, ''))}
                    onChange={handleChange}
                    maxLength="11"
                    placeholder="연락 받으실 번호를 입력해 주세요."
                  />
                </label>
                <label>
                  <span className="inputTit">이메일</span>
                  <input
                    type="text"
                    name="manageEmail"
                    title="이메일"
                    ref={(el) => (inputRef.current[5] = el)}
                    value={values.manageEmail}
                    onChange={handleChange}
                    placeholder="이메일을 입력해 주세요."
                  />
                </label>
              </div>
              <PersoanlInfo privacyChk={privacyChk} setPrivacyChk={setPrivacyChk} />
              <div className="btn">
                <button className="apply">신청하기</button>
              </div>
            </div>
          </div>
          <div className="closeBtn" onClick={closeModal}>
            <button type="submit">
              <BsXLg />
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Modal;
