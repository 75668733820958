import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PersonalInformation from '../../../personalInformation/personalInformation';
import axios from 'axios';
import { useDaumPostcodePopup } from 'react-daum-postcode';
import { postcodeScriptUrl } from 'react-daum-postcode/lib/loadPostcode';
import ConfirmToProduct from '../DownLoad/confirm/ConfirmToProduct';

const ProductInq = () => {
  const { pathname } = useLocation();
  const productInqRef = useRef(null);
  const inputRef = useRef([]);
  const open = useDaumPostcodePopup(postcodeScriptUrl);
  const emailForm = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,5}$/i;
  const rgNumWithCommas = (str) => {
    return str.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  };
  const [productGubun, setProductGubun] = useState([]);
  const inputValue = {
    corName: '',
    qSize: '',
    mngName: '',
    manage: '',
    phone: '',
    email: '',
    addr: '',
    addrDetail: '',
    contents: '',
    privacyChk: '',
  };

  const [values, setValues] = useState(inputValue);
  const [loading, setLoading] = useState(false);
  const [privacyChk, setPrivacyChk] = useState(false);
  const [downloadConfirm, setDownloadConfirm] = useState(false);

  const handleComplete = (data) => {
    setValues({ ...values, addr: data.roadAddress });
  };

  const gubunHandler = useCallback(
    (checked, id) => {
      if (checked) {
        setProductGubun([...productGubun, id]);
      } else {
        setProductGubun(productGubun.filter((el) => el !== id));
      }
    },
    [productGubun],
  );

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    if (downloadConfirm === false) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [downloadConfirm]);

  useEffect(() => {
    if (pathname === '/customer/product-inquiry/contact-us') {
      const posY = productInqRef?.current?.offsetTop;
      window.scrollTo({
        top: posY,
        behavior: 'smooth',
      });
    }
  }, [pathname]);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (loading) {
        return false;
      }
      if (productGubun.length === 0) {
        alert('관심제품을 선택해주세요.');
        inputRef.current[9].focus();
        return false;
      }
      for (let i = 0; i < inputRef.current.length; i++) {
        if (inputRef.current[i].value === '' || !inputRef.current[i].value.trim()) {
          alert(inputRef.current[i].title + '을(를) 입력해주세요.');
          inputRef.current[i].focus();
          return false;
        }
      }
      if (!emailForm.test(values.email)) {
        alert('이메일 양식을 확인해주세요.');
        inputRef.current[5].focus();
        return false;
      }
      if (privacyChk === false) {
        alert('개인정보 수집 및 이용을 동의해주세요.');
        return false;
      }

      let payload = {
        ...values,
        qSize: values.qSize,
        privacyChk: 'Y',
        mngName: `${values.mngName}/${values.manage}`,
        phone: values.phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'),
      };

      try {
        setLoading(true);
        for (let i = 0; i < productGubun.length; i++) {
          payload['productGubunList[' + i + ']'] = productGubun[i];
        }
        axios
          .post(`/consulting/product`, payload, {
            headers: {
              'Content-Type': `application/x-www-form-urlencoded`,
            },
          })
          .then(() => {
            setValues({ ...inputValue, productGubun: [] });
            setPrivacyChk(false);
            setProductGubun([]);
            setDownloadConfirm(!downloadConfirm);
          });
      } catch (error) {
        alert('오류가 발생했습니다. 관리자에게 연락 부탁드립니다.');
      } finally {
        setLoading(false);
      }
    },
    [values, productGubun, privacyChk],
  );

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="product w1200" ref={productInqRef}>
          <div className="selectForm">
            <h3>관심 제품을 선택해 주세요.</h3>
            <div className="padding">
              <div className="flex">
                <label className="checkBox">
                  <input
                    type="checkbox"
                    className="radioBtn"
                    name="E"
                    id="E"
                    ref={(el) => (inputRef.current[9] = el)}
                    onChange={(e) => {
                      gubunHandler(e.currentTarget.checked, 'E');
                    }}
                    checked={productGubun.includes('E')}
                  />
                  <span className="checkmark"></span>
                  <span className="rTitle">EG-Platform</span>
                </label>
                <label className="checkBox">
                  <input
                    type="checkbox"
                    className="radioBtn"
                    name="R"
                    id="R"
                    onChange={(e) => {
                      gubunHandler(e.currentTarget.checked, 'R');
                    }}
                    checked={productGubun.includes('R')}
                  />
                  <span className="checkmark"></span>
                  <span className="rTitle">수신보안</span>
                </label>
                <label className="checkBox">
                  <input
                    type="checkbox"
                    className="radioBtn"
                    name="SD"
                    id="SD"
                    onChange={(e) => {
                      gubunHandler(e.currentTarget.checked, 'SD');
                    }}
                    checked={productGubun.includes('SD')}
                  />
                  <span className="checkmark"></span>
                  <span className="rTitle">발신보안</span>
                </label>
                {/*                <label className="checkBox">
                  <input
                    type="checkbox"
                    className="radioBtn"
                    name="EL"
                    id="EL"
                    onChange={(e) => {
                      gubunHandler(e.currentTarget.checked, 'EL');
                    }}
                    checked={productGubun.includes('EL')}
                  />
                  <span className="checkmark"></span>
                  <span className="rTitle">EG-Linker</span>
                </label>*/}
                <label className="checkBox">
                  <input
                    type="checkbox"
                    className="radioBtn"
                    name="DE"
                    id="DE"
                    onChange={(e) => {
                      gubunHandler(e.currentTarget.checked, 'DE');
                    }}
                    checked={productGubun.includes('DE')}
                  />
                  <span className="checkmark"></span>
                  <span className="rTitle">악성메일 모의훈련</span>
                </label>
              </div>
            </div>
          </div>
          <div className="inputForm">
            <h3>문의에 필요한 정보를 입력해 주세요.</h3>
            <div className="noflex">
              <div className="inquiryInput flex">
                <label>
                  <span className="rTitle">기업명</span>
                  <input
                    type="text"
                    name="corName"
                    title="기업명"
                    ref={(el) => (inputRef.current[0] = el)}
                    value={values.corName}
                    onChange={handleChange}
                    placeholder="기업명을 입력해 주세요."
                  />
                </label>
                <label>
                  <span className="rTitle">회사 규모</span>
                  <input
                    type="text"
                    name="qSize"
                    title="회사 규모"
                    ref={(el) => (inputRef.current[1] = el)}
                    value={values.qSize}
                    onChange={handleChange}
                    placeholder="직원 수를 입력해 주세요"
                  />
                </label>
              </div>
              <div className="inquiryInput flex">
                <label>
                  <span className="rTitle">담당자명</span>
                  <input
                    type="text"
                    name="mngName"
                    title="담당자명"
                    ref={(el) => (inputRef.current[2] = el)}
                    value={values.mngName}
                    onChange={handleChange}
                    placeholder="담당자분의 성함을 입력해 주세요."
                  />
                </label>
                <label>
                  <span className="rTitle">직함</span>
                  <input
                    type="text"
                    name="manage"
                    title="직함"
                    ref={(el) => (inputRef.current[3] = el)}
                    value={values.manage}
                    onChange={handleChange}
                    placeholder="담당자분의 직함을 입력해 주세요."
                  />
                </label>
              </div>
              <div className="inquiryInput flex">
                <label>
                  <span className="rTitle">연락처</span>
                  <input
                    type="text"
                    name="phone"
                    title="연락처"
                    ref={(el) => (inputRef.current[4] = el)}
                    value={rgNumWithCommas(values.phone.replace(/[^0-9]/g, ''))}
                    onChange={handleChange}
                    maxLength="11"
                    placeholder="연락 받으실 번호를 입력해 주세요."
                  />
                </label>
                <label>
                  <span className="rTitle">이메일</span>
                  <input
                    type="text"
                    name="email"
                    title="이메일"
                    ref={(el) => (inputRef.current[5] = el)}
                    value={values.email}
                    onChange={handleChange}
                    placeholder="이메일을 입력해 주세요."
                  />
                </label>
              </div>
              <div className="address">
                <div className="streetAdd flex">
                  <label>
                    <span className="rTitle">기업주소</span>
                    <input
                      type="text"
                      name="addr"
                      title="기업주소"
                      ref={(el) => (inputRef.current[6] = el)}
                      value={values.addr}
                      onChange={handleChange}
                      onClick={() => {
                        open({ onComplete: handleComplete });
                      }}
                      placeholder="기업 주소를 입력해 주세요."
                      tabIndex="-1"
                    />
                  </label>
                  <div className="findBtn">
                    <button
                      type="button"
                      onChange={handleChange}
                      onClick={() => {
                        open({ onComplete: handleComplete });
                      }}
                    >
                      주소 찾기
                    </button>
                  </div>
                </div>
                <label className="detailAdd">
                  <input
                    type="text"
                    name="addrDetail"
                    title="상세주소"
                    ref={(el) => (inputRef.current[7] = el)}
                    value={values.addrDetail}
                    onChange={handleChange}
                    placeholder="상세 주소를 입력해 주세요."
                    tabIndex="-1"
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="textArea">
            <h3>제품의 어느 점이 궁금하신가요?</h3>
            <textarea
              name="contents"
              title="검토사항"
              ref={(el) => (inputRef.current[8] = el)}
              value={values.contents}
              onChange={handleChange}
              placeholder="도입 시 주요하게 검토 중이신 사항에 대하여 입력해 주세요."
            />
          </div>
          <PersonalInformation privacyChk={privacyChk} setPrivacyChk={setPrivacyChk} />
          <div className="btn">
            <button type="submit" className="apply">
              신청하기
            </button>
          </div>
        </div>
      </form>
      {downloadConfirm && <ConfirmToProduct setDownloadConfirm={setDownloadConfirm} />}
    </>
  );
};

export default ProductInq;
