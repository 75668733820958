export const LogoImg = [
  {
    id: 1,
    src: '/images/logo/logo01_lgchem.png',
  },
  {
    id: 2,
    src: '/images/logo/logo02_hhi.png',
  },
  {
    id: 3,
    src: '/images/logo/logo03_nexencorp.PNG',
  },
  {
    id: 4,
    src: '/images/logo/logo04_kolonbenit.PNG',
  },
  {
    id: 5,
    src: '/images/logo/logo05_daehanship.PNG',
  },
  {
    id: 6,
    src: '/images/logo/logo06_hjsc.PNG',
  },
  {
    id: 7,
    src: '/images/logo/logo07_wonik.png',
  },
  {
    id: 8,
    src: '/images/logo/logo08_hsdengine.PNG',
  },
  {
    id: 9,
    src: '/images/logo/logo09_kiswire.PNG',
  },
  {
    id: 10,
    src: '/images/logo/logo10_sfa.PNG',
  },
  {
    id: 11,
    src: '/images/logo/logo11_donghee.PNG',
  },
  {
    id: 12,
    src: '/images/logo/logo12_dongah.png',
  },
  {
    id: 13,
    src: '/images/logo/logo13_ecoplastic.PNG',
  },
  {
    id: 14,
    src: '/images/logo/logo14_sl.PNG',
  },
  {
    id: 15,
    src: '/images/logo/logo15_kai.PNG',
  },
  {
    id: 16,
    src: '/images/logo/logo16_bnk.PNG',
  },
  {
    id: 17,
    src: '/images/logo/logo17_aju.PNG',
  },
  {
    id: 18,
    src: '/images/logo/logo18_gc.PNG',
  },
  {
    id: 19,
    src: '/images/logo/logo19_jeilpharm.PNG',
  },
  {
    id: 20,
    src: '/images/logo/logo20_boryung.PNG',
  },
  {
    id: 21,
    src: '/images/logo/logo21_pr.PNG',
  },
  {
    id: 22,
    src: '/images/logo/logo22_cnuh.PNG',
  },
  {
    id: 23,
    src: '/images/logo/logo23_kicpa.PNG',
  },
  {
    id: 24,
    src: '/images/logo/logo24_krict.PNG',
  },
];
