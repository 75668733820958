import React, { useEffect, useRef, useState } from 'react';
import { GrDownload } from 'react-icons/gr';
import Modal from './modal/modal';
import { useLocation } from 'react-router-dom';
import ConfirmToDownload from './confirm/ConfirmToDownload';

const DownLoad = () => {
  const [download, setDownload] = useState(false);
  const [downloadConfirm, setDownloadConfirm] = useState(false);
  const [productTarget, setProductTarget] = useState([]);

  useEffect(() => {
    if (downloadConfirm === false) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [downloadConfirm]);

  return (
    <>
      <div className="download">
        <div className="container">
          <div className="flex">
            <div className="egP">
              <h3>EG-Platform</h3>
              <div className="downloadBox">
                <div className="downloadItem egProposal">
                  <p>제안서 다운로드</p>
                  <button
                    onClick={() => {
                      setDownload(!download);
                      setProductTarget('EG_P');
                    }}
                  >
                    <span>다운로드</span>
                  </button>
                </div>
                <div className="downloadItem egBrochure">
                  <p>브로슈어 다운로드</p>
                  <button
                    onClick={() => {
                      setDownload(!download);
                      setProductTarget('EG_B');
                    }}
                  >
                    <span>다운로드</span>
                  </button>
                </div>
              </div>
            </div>
            {/*            <div className="egL">
              <h3>EG-Linker</h3>
              <div className="downloadBox">
                <div className="downloadItem egProposal">
                  <p>제안서 다운로드</p>
                  <button
                    onClick={() => {
                      setDownload(!download);
                      setProductTarget('EL_P');
                    }}
                  >
                    <span>다운로드</span>
                  </button>
                </div>
                <div className="downloadItem egBrochure">
                  <p>브로슈어 다운로드</p>
                  <button
                    onClick={() => {
                      setDownload(!download);
                      setProductTarget('EL_B');
                    }}
                  >
                    <span>다운로드</span>
                  </button>
                </div>
              </div>
            </div>*/}
          </div>
        </div>
      </div>
      {download && (
        <Modal
          type={'download'}
          productTarget={productTarget}
          setProductTarget={{ setProductTarget }}
          closeModal={() => {
            setDownload(!download);
          }}
          setDownloadConfirm={setDownloadConfirm}
        />
      )}
      {downloadConfirm && <ConfirmToDownload setDownloadConfirm={setDownloadConfirm} />}
      {/*<ConfirmToDownload setDownloadConfirm={setDownloadConfirm} />*/}
    </>
  );
};

export default DownLoad;
