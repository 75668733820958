import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NavData } from './NavData';
import EntireNav from './EntireNav';

const Header = ({ noWheel, setToggle, toggle }) => {
  const { pathname } = useLocation();
  const [isFixed, setIsFixed] = useState(false);
  const [dep1, setDep1] = useState(null);
  const [isHovering, setIsHovering] = useState(false);
  const [isHoveringNum, setIsHoveringNum] = useState(null);
  const toggleIcon = useCallback(
    (e) => {
      e.stopPropagation();
      setToggle((prev) => !prev);
    },
    [toggle],
  );

  useEffect(() => {
    setIsHovering(false);
  }, [pathname]);

  useEffect(() => {
    if (pathname === '/') {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  }, [pathname]);

  return (
    <header className={!isFixed || noWheel ? 'header fixed' : 'header'}>
      <div className="inner">
        <h1>
          <Link to="/" className="logo">
            <img src="/images/logo.png" alt="로고" />
          </Link>
        </h1>
        <nav className="gnb">
          <ul className="gnbList">
            {NavData.map((it, idx) => (
              <li key={it.id}>
                <Link
                  to={it.link}
                  className={isHovering && isHoveringNum === idx ? 'gnbItem active' : 'gnbItem'}
                  onMouseOver={() => {
                    setIsHovering(true);
                    setIsHoveringNum(idx);
                  }}
                  onMouseLeave={() => {
                    setIsHovering(false);
                    setIsHoveringNum(idx);
                  }}
                >
                  {it.title}
                </Link>
                {isHovering && (
                  <ul className={isHoveringNum === idx ? 'lnb active' : 'lnb'}>
                    {it.arr.map((its) => (
                      <li
                        key={its.id}
                        className="lnbList"
                        onMouseOver={() => {
                          setIsHovering(true);
                        }}
                        onMouseLeave={() => {
                          setIsHovering(false);
                        }}
                      >
                        <Link to={its.link}>{its.title}</Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
          <div className={`toggle ${toggle ? 'active' : ''}`} onClick={toggleIcon}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </nav>
      </div>
      {toggle && <EntireNav />}
      {/*<EntireNav toggle={toggle} />*/}
    </header>
  );
};

export default Header;
