import React, { useEffect, useLayoutEffect, useState } from 'react';
import axios from 'axios';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import ImageLazyLoading from '../../../../hooks/imageLazyLoading';
import removeTags from '../../../../hooks/useRemoveTag';
import AlterDate from '../../../../hooks/useAlterDate';
import Pagination from '../../../../components/pagination/Pagination';

const News = () => {
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [list, setList] = useState();
  const [pagingInfo, setPagingInfo] = useState();
  const currentPage = searchParams.get('pageNo') || 1;
  const searchType = searchParams.get('searchType');
  const searchText = searchParams.get('searchText');

  useLayoutEffect(() => {
    const getList = async () => {
      const response = await axios.get(
        `/board/news/list?pageSize=6&pageNo=${currentPage}${searchType ? `&searchType=${searchType}` : ''}${searchText ? `&searchText=${searchText}` : ''}`,
      );
      if (response.data.boardList.length !== 0) {
        setList(response.data.boardList);
        setPagingInfo(response.data.boardParam);
      } else {
        setList([]);
      }
    };
    getList();
  }, [searchParams]);

  return (
    <>
      {list ? (
        <ul className="itemBox">
          {list?.map((it) => {
            return (
              <li className="newsItem" key={it.idx}>
                <Link
                  to={{
                    pathname: `detail/${it.idx}`,
                  }}
                  className="flex"
                >
                  <figure className="image">
                    <ImageLazyLoading src={`https://backeg.kiwontech.com/file/download?idx=${it.mainImgIdx}`} alt="{it.title}" />
                  </figure>
                  <div className="text">
                    <h3>{it.title}</h3>
                    <p className="newsContent">{removeTags(it.contents)}</p>
                    <p className="newsDate">{AlterDate(it.rgsde)}</p>
                  </div>
                </Link>
              </li>
            );
          })}
        </ul>
      ) : (
        <div className="loading">
          <figure className="loadIcon">
            {/*<BiLoaderCircle />*/}
            <img src="/images/icon/spinner.gif" alt="" />
          </figure>
          <p>Loading...</p>
        </div>
      )}
      {list?.length === 0 && <p className="noList">리스트가 없습니다.</p>}
      {list?.length !== 0 && pagingInfo ? <Pagination pagingInfo={pagingInfo} /> : null}
    </>
  );
};

export default News;
