import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { BsArrowRight, BsArrowLeft } from 'react-icons/bs';
import axios from 'axios';
import AlterDate from '../../../../hooks/useAlterDate';
import removeTags from '../../../../hooks/useRemoveTag';

const NewsDetail = () => {
  const { pathname } = useLocation();
  const [detail, setDetail] = useState('');
  const idx = pathname.split('/')[4];
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getDetail = async () => {
      const response = await axios.get(`/board/news?boardType=n&idx=${idx}`);
      setDetail(response.data.board);
      setLoading(false);
    };
    getDetail();
  }, [pathname]);

  if (!detail) return false;
  return (
    <div className="w1200">
      {detail && (
        <>
          <div className="datailPage">
            <div className="title">
              <h2>{detail.title}</h2>
              <span className="date">{AlterDate(detail.rgsde)}</span>
            </div>
            <div className="contentItem">
              <div className="content" dangerouslySetInnerHTML={{ __html: detail.contents }}></div>
              {detail.articleUrl && (
                <p className="link">
                  [기사 원문]
                  <Link to={detail.articleUrl} target="_blank">
                    {detail.articleUrl}
                  </Link>
                </p>
              )}
            </div>
          </div>
          <div className="direction">
            {detail.prevIdx !== 0 ? (
              <div className="leftArrow">
                <Link to={`/news/news/detail/${detail.prevIdx}`} style={{ pointerEvents: detail.prevIdx === 0 ? 'none' : '' }}>
                  <span>
                    <BsArrowLeft />
                  </span>
                  <span>이전글</span>
                </Link>
              </div>
            ) : (
              <div className="leftArrow leftArrowNone">
                <Link to={`/news/news/detail/${detail.prevIdx}`} style={{ pointerEvents: detail.prevIdx === 0 ? 'none' : '' }}>
                  <span>
                    <BsArrowLeft />
                  </span>
                  <span>이전글</span>
                </Link>
              </div>
            )}
            <div className="centerList">
              <Link to="/news/news">
                <div className="listIcon">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <p>List</p>
              </Link>
            </div>
            {detail.nextIdx !== 0 ? (
              <div className="rightArrow">
                <Link to={`/news/news/detail/${detail.nextIdx}`} style={{ pointerEvents: detail.nextIdx === 0 ? 'none' : '' }}>
                  <span>다음글</span>
                  <span>
                    <BsArrowRight />
                  </span>
                </Link>
              </div>
            ) : (
              <div className="rightArrow rightArrowNone">
                <Link to={`/news/news/detail/${detail.nextIdx}`} style={{ pointerEvents: detail.nextIdx === 0 ? 'none' : '' }}>
                  <span>다음글</span>
                  <span>
                    <BsArrowRight />
                  </span>
                </Link>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default NewsDetail;
