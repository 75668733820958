import { Route, Routes, useLocation } from 'react-router-dom';
import { throttle } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import './App.css';
import './scss/scrollBar.scss';
import '../src/scss/font.css';
import '../src/scss/keyframse.scss';
import '../src/components/header/header.scss';
import '../src/components/footer/footer.scss';
import './scss/style.scss';
import '../src/pages/popup/style.scss';
import '../src/pages/personalInformation/style.scss';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Main from './pages/main/Main';
import CustomerSupport from './pages/customerSupport';
import NewsPages from './pages/News';
import EgPlatform from './pages/egPlatform';
import TermPersonal from './pages/popup/termPersonal';
import TermEmail from './pages/popup/termEmail';
import SimulatedTraining from './pages/simulatedTraining';
import ReceivingSecurity from './pages/productComposition/receivingSecurity';
import OutgoingSecurity from './pages/productComposition/outgoingSecurity';
import TechnologyCertification from './pages/technologyCertification';
import EgLinker from './pages/productComposition/egLinker';
import Certification from './pages/productComposition/CC';
import axios from 'axios';

function App() {
  if (process.env.NODE_ENV !== 'development') {
    axios.defaults.baseURL = 'https://backeg.kiwontech.com';
  }

  const { pathname } = useLocation();
  const [noWheel, setNoWheel] = useState(false);
  const [toggle, setToggle] = useState(false);

  const throttledScroll = useMemo(
    () =>
      throttle(() => {
        if (window.scrollY !== 0) {
          setNoWheel(true);
        } else {
          setNoWheel(false);
        }
      }, 100),
    [noWheel],
  );

  useEffect(() => {
    setToggle(false);
  }, [pathname]);

  useEffect(() => {
    window.addEventListener('scroll', throttledScroll);
    return () => {
      window.removeEventListener('scroll', throttledScroll);
    };
  }, [throttledScroll]);

  return (
    <>
      <Header noWheel={noWheel} setToggle={setToggle} toggle={toggle} />
      <main>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/eg-platform" element={<EgPlatform />} />
          <Route path="/eg-platform/*" element={<EgPlatform />} />
          <Route path="/simulated-training" element={<SimulatedTraining />} />
          <Route path="/simulated-training/*" element={<SimulatedTraining />} />
          <Route path="/product-composition/receiving-security" element={<ReceivingSecurity />} />
          <Route path="/product-composition/outgoing-security" element={<OutgoingSecurity />} />
          <Route path="/product-composition/EG-Linker" element={<EgLinker />} />
          <Route path="/product-composition/CC-certification" element={<Certification />} />
          <Route path="/technology-certification" element={<TechnologyCertification />} />
          <Route path="/technology-certification/*" element={<TechnologyCertification />} />
          <Route path="/customer/*" element={<CustomerSupport />} />
          <Route path="/news/*" element={<NewsPages />} />
          <Route path="/popup/term-personal" element={<TermPersonal />} />
          <Route path="/popup/term-email" element={<TermEmail />} />
        </Routes>
      </main>
      <Footer />
    </>
  );
}

export default App;
