import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { Bebefits } from './Bebefits';
import { useLocation } from 'react-router-dom';
import PersonalInformation from '../../../personalInformation/personalInformation';
import axios from 'axios';
import ConfirmToPartner from '../DownLoad/confirm/ConfirmToPartner';
/*import ConfirmToPartner from '../DownLoad/confirm/ConfirmToPartner';*/

const PartnershipInq = () => {
  const { pathname } = useLocation();
  const partnerInqRef = useRef(null);
  const inputRef = useRef([]);
  const emailForm = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,5}$/i;
  const rgNumWithCommas = (str) => {
    return str.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  };
  const inputValue = {
    corName: '',
    qSize: '',
    mngName: '',
    manage: '',
    phone: '',
    email: '',
    contents: '',
    privacyChk: '',
  };
  const [values, setValues] = useState(inputValue);
  const [loading, setLoading] = useState(false);
  const [privacyChk, setPrivacyChk] = useState(false);
  const [downloadConfirm, setDownloadConfirm] = useState(false);

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (loading) {
        return false;
      }
      for (let i = 0; i < inputRef.current.length; i++) {
        if (inputRef.current[i].value === '' || !inputRef.current[i].value.trim()) {
          alert(inputRef.current[i].title + '을(를) 입력해주세요.');
          inputRef.current[i].focus();
          return false;
        }
      }
      if (!emailForm.test(values.email)) {
        alert('이메일 양식을 확인해주세요.');
        inputRef.current[5].focus();
        return false;
      }
      if (privacyChk === false) {
        alert('개인정보 수집 및 이용을 동의해주세요.');
        return false;
      }

      try {
        setLoading(true);
        axios
          .post(
            `/consulting/partnership`,
            {
              ...values,
              qSize: values.qSize,
              privacyChk: 'Y',
              mngName: `${values.mngName}/${values.manage}`,
              phone: values.phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'),
            },
            {
              headers: {
                'Content-Type': `application/x-www-form-urlencoded`,
              },
            },
          )
          .then((res) => {
            setValues({ ...inputValue });
            setPrivacyChk(false);
          });
      } catch (error) {
        alert('오류가 발생했습니다. 관리자에게 연락 부탁드립니다.');
      } finally {
        setLoading(false);
        setDownloadConfirm(!downloadConfirm);
      }
    },
    [values, privacyChk],
  );

  useEffect(() => {
    if (downloadConfirm === false) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [downloadConfirm]);

  useEffect(() => {
    if (pathname === '/customer/partnership-inquiry/contact-us') {
      const posY = partnerInqRef?.current?.offsetTop;
      window.scrollTo({
        top: posY,
        behavior: 'smooth',
      });
    }
  }, [pathname]);

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="partner w1200">
          <div className="benefitDiv">
            <h2>
              기원테크의 <span className="blue">새로운 공식 파트너사</span>를 모집 중입니다.
            </h2>
            <ul className="benefitsBox">
              {Bebefits.map((it) => (
                <li key={it.id}>
                  <span className="case">{it.case}</span>
                  <p className="titleTop">{it.title}</p>
                  <p className="benefitsDetail">{it.detail}</p>
                </li>
              ))}
            </ul>
          </div>
          <div className="inputForm" ref={partnerInqRef}>
            <h3>문의에 필요한 정보를 입력해 주세요.</h3>
            <div className="noflex">
              <div className="flex">
                <label>
                  <span className="rTitle">기업명</span>
                  <input
                    type="text"
                    name="corName"
                    title="기업명"
                    ref={(el) => (inputRef.current[0] = el)}
                    value={values.corName}
                    onChange={handleChange}
                    placeholder="기업명을 입력해 주세요."
                  />
                </label>
                <label>
                  <span className="rTitle">회사 규모</span>
                  <input
                    type="text"
                    name="qSize"
                    title="회사 규모"
                    ref={(el) => (inputRef.current[1] = el)}
                    value={values.qSize}
                    onChange={handleChange}
                    placeholder="직원 수를 입력해 주세요"
                  />
                </label>
              </div>
              <div className="flex">
                <label>
                  <span className="rTitle">담당자명</span>
                  <input
                    type="text"
                    name="mngName"
                    title="담당자명"
                    ref={(el) => (inputRef.current[2] = el)}
                    value={values.mngName}
                    onChange={handleChange}
                    placeholder="담당자분의 성함을 입력해 주세요."
                  />
                </label>
                <label>
                  <span className="rTitle">직함</span>
                  <input
                    type="text"
                    name="manage"
                    title="직함"
                    ref={(el) => (inputRef.current[3] = el)}
                    value={values.manage}
                    onChange={handleChange}
                    placeholder="담당자분의 직함을 입력해 주세요."
                  />
                </label>
              </div>
              <div className="flex">
                <label>
                  <span className="rTitle">연락처</span>
                  <input
                    type="text"
                    name="phone"
                    title="연락처"
                    ref={(el) => (inputRef.current[4] = el)}
                    value={rgNumWithCommas(values.phone.replace(/[^0-9]/g, ''))}
                    onChange={handleChange}
                    maxLength="11"
                    placeholder="연락 받으실 번호를 입력해 주세요."
                  />
                </label>
                <label>
                  <span className="rTitle">이메일</span>
                  <input
                    type="text"
                    name="email"
                    title="이메일"
                    ref={(el) => (inputRef.current[5] = el)}
                    value={values.email}
                    onChange={handleChange}
                    placeholder="이메일을 입력해 주세요."
                  />
                </label>
              </div>
              <div className="textArea">
                <textarea
                  name="contents"
                  title="검토사항"
                  ref={(el) => (inputRef.current[6] = el)}
                  value={values.contents}
                  onChange={handleChange}
                  placeholder="도입 시 주요하게 검토 중이신 사항에 대하여 입력해 주세요."
                />
              </div>
            </div>
          </div>
          <PersonalInformation privacyChk={privacyChk} setPrivacyChk={setPrivacyChk} />
          <div className="btn">
            <button type="submit" className="apply">
              신청하기
            </button>
          </div>
        </div>
      </form>
      {downloadConfirm && <ConfirmToPartner setDownloadConfirm={setDownloadConfirm} />}
    </>
  );
};

export default PartnershipInq;
