import React, { useCallback, useEffect, useRef, useState } from 'react';
import PersonalInformation from '../personalInformation/personalInformation';
import { Controller, Scene } from 'react-scrollmagic';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ConfirmToTraining from './ConfirmToTraining';

const SimulatedTraining = () => {
  const tabRef = useRef([]);
  const inputRef = useRef([]);
  const { pathname } = useLocation();
  const [isFixed, setIsFixed] = useState(false);
  const [selectedBtn, setSelectedBtn] = useState('');
  const emailForm = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,5}$/i;
  const rgNumWithCommas = (str) => {
    return str.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  };
  const inputValue = {
    corName: '',
    qSize: '',
    mngName: '',
    manage: '',
    phone: '',
    email: '',
    contents: '',
    privacyChk: '',
  };
  const [values, setValues] = useState(inputValue);
  const [loading, setLoading] = useState(false);
  const [privacyChk, setPrivacyChk] = useState(false);
  const [downloadConfirm, setDownloadConfirm] = useState(false);

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (loading) {
        return false;
      }
      for (let i = 0; i < inputRef.current.length; i++) {
        if (inputRef.current[i].value === '' || !inputRef.current[i].value.trim()) {
          alert(inputRef.current[i].title + '을(를) 입력해주세요.');
          inputRef.current[i].focus();
          return false;
        }
      }
      if (!emailForm.test(values.email)) {
        alert('이메일 양식을 확인해주세요.');
        inputRef.current[5].focus();
        return false;
      }
      if (privacyChk === false) {
        alert('개인정보 수집 및 이용을 동의해주세요.');
        return false;
      }

      try {
        setLoading(true);
        axios
          .post(
            `/consulting/simulated-training`,
            {
              ...values,
              qSize: values.qSize,
              privacyChk: 'Y',
              mngName: `${values.mngName}/${values.manage}`,
              phone: values.phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'),
            },
            {
              headers: {
                'Content-Type': `application/x-www-form-urlencoded`,
              },
            },
          )
          .then((res) => {
            setValues({ ...inputValue });
            setPrivacyChk(false);
            setDownloadConfirm(!downloadConfirm);
          });
      } catch (error) {
        alert('오류가 발생했습니다. 관리자에게 연락 부탁드립니다.');
      } finally {
        setLoading(false);
      }
    },
    [values, privacyChk],
  );

  const handleScroll = () => {
    if (window.scrollY > 450) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
    if (tabRef.current[0].offsetTop - 300 <= window.scrollY) {
      setSelectedBtn('outline');
    }
    if (tabRef.current[1].offsetTop - 300 <= window.scrollY) {
      setSelectedBtn('necessity');
    }
    if (tabRef.current[2].offsetTop - 300 <= window.scrollY) {
      setSelectedBtn('course');
    }
    if (tabRef.current[3].offsetTop - 300 <= window.scrollY) {
      setSelectedBtn('proceedings');
    }
    if (tabRef.current[4].offsetTop - 300 <= window.scrollY) {
      setSelectedBtn('inquiry');
    }
    if (tabRef.current[0].offsetTop - 300 > window.scrollY) {
      setSelectedBtn('');
    }
  };

  const moveToArea = (index) => {
    tabRef?.current[index]?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      // inline: 'start',
    });
  };

  useEffect(() => {
    if (downloadConfirm === false) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [downloadConfirm]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isFixed]);

  useEffect(() => {
    if (pathname === '/simulated-training') {
      window.scrollTo(0, 0);
    }
    if (pathname === '/simulated-training/promotion-outline') {
      moveToArea(0);
      setSelectedBtn('outline');
    }
    if (pathname === '/simulated-training/necessity') {
      moveToArea(1);
      setSelectedBtn('necessity');
    }
    if (pathname === '/simulated-training/training-course') {
      moveToArea(2);
      setSelectedBtn('course');
    }
    if (pathname === '/simulated-training/proceedings') {
      moveToArea(3);
      setSelectedBtn('proceedings');
    }
    if (pathname === '/simulated-training/inquiry') {
      moveToArea(4);
      setSelectedBtn('inquiry');
    }
  }, [pathname]);

  return (
    <>
      <section className="inboundSecu simulated">
        <Controller>
          <Scene triggerElement="#triggerPoint00" classToggle="active_mv">
            <div className="container" id="triggerPoint00">
              <p className="engTitle">Simulated Training</p>
              <h2>모의훈련</h2>
              <p>
                이메일 보안 전문 기업이 제공하는
                <br className="w815" /> 악성 메일 모의 훈련으로
                <br className="w330" />
                악성 메일 대응 현황을 <br className="w815" /> 확인하고 기업 보안 인식을 확립하세요.
              </p>
            </div>
          </Scene>
        </Controller>
      </section>
      <section
        className="outLine"
        ref={(element) => {
          tabRef.current[0] = element;
        }}
      >
        <div className={isFixed ? 'menuPart fixed' : 'menuPart'}>
          <ul className="tabMenu w660">
            <li className={selectedBtn === 'outline' ? 'active' : ''}>
              <button
                type="button"
                onClick={() => {
                  moveToArea(0);
                }}
              >
                추진개요
              </button>
            </li>
            <li className={selectedBtn === 'necessity' ? 'active' : ''}>
              <button
                type="button"
                onClick={() => {
                  moveToArea(1);
                }}
              >
                추진필요성
              </button>
            </li>
            <li className={selectedBtn === 'course' ? 'active' : ''}>
              <button
                type="button"
                onClick={() => {
                  moveToArea(2);
                }}
              >
                훈련과정
              </button>
            </li>
            <li className={selectedBtn === 'proceedings' ? 'active' : ''}>
              <button
                type="button"
                onClick={() => {
                  moveToArea(3);
                }}
              >
                진행절차
              </button>
            </li>
            <li className={selectedBtn === 'inquiry' ? 'active' : ''}>
              <button
                type="button"
                onClick={() => {
                  tabRef?.current[4]?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  });
                }}
              >
                신청하기
              </button>
            </li>
          </ul>
        </div>
        <div className="container">
          <h2>악성 메일 모의 훈련 지원</h2>
          <p>이메일 보안 전문 기업 기원테크가 악성 메일 모의 훈련을 지원합니다.</p>
          <ul className="flex">
            <li>
              <p>
                사칭 계정에 대한 사기 메일
                <br />
                대처 현황 파악
              </p>
            </li>
            <li>
              <p>
                개인 정보 입력 악성 메일 <br />
                대처 현황 파악
              </p>
            </li>
            <li>
              <p>
                사전 예방으로 정보 유출 <br /> 가능성 최소화
              </p>
            </li>
            <li>
              <p>
                구성원의 보안 의식 수준 <br />
                확인 및 향상
              </p>
            </li>
            <li>
              <p>
                훈련 결과를 통한 향후 <br /> 교육 방안 수립 가능
              </p>
            </li>
          </ul>
        </div>
      </section>
      <section
        className="necessity"
        ref={(element) => {
          tabRef.current[1] = element;
        }}
      >
        <div className="w1200">
          <h2>
            정보 유출 피해 예방의 첫 걸음은 <span className="cyan">사내 보안 교육 훈련</span>
          </h2>
          <p>
            고도화 · 지능화 되는 해킹, 사칭 메일에 대응하기 위해 <span className="bold">사전 모의 훈련</span>을 통해 경각심 제고가 필요합니다.
          </p>
          <ul className="flex">
            <li>
              <span>위 · 변조 메일 확인 비율</span>
              <p>65%</p>
            </li>
            <li>
              <span>위험 링크 확인 비율</span>
              <p>58%</p>
            </li>
            <li>
              <span>개인 정보 입력 비율</span>
              <p>29%</p>
            </li>
          </ul>
        </div>
      </section>
      <section
        className="trainingCourse"
        ref={(element) => {
          tabRef.current[2] = element;
        }}
      >
        <div className="w1200">
          <h2>기업 맞춤형 시나리오 기획 후, 모의 훈련 진행</h2>
          <p>훈련 과정을 통해 악성 메일에 대한 위기 대응 능력을 향상시킵니다.</p>
          <ul className="flex">
            <li>
              <span>1단계</span>
              <h3>메일 본문</h3>
              <p>
                00 기업 사칭
                <br />
                링크 클릭 유도 메일 발송
              </p>
            </li>
            <li>
              <span>2단계</span>
              <h3>피싱 사이트</h3>
              <p>
                개인정보 입력
                <br />
                유도 피싱 사이트
              </p>
            </li>
            <li>
              <span>3단계</span>
              <h3>피싱 사이트 결과 페이지</h3>
              <p>접속 지연 페이지</p>
            </li>
          </ul>
        </div>
      </section>
      <section
        className="proceedings"
        ref={(element) => {
          tabRef.current[3] = element;
        }}
      >
        <div className="container">
          <h2>모의 훈련 진행 절차</h2>
          <p>악성 메일 모의 훈련은 아래의 절차에 따라 체계적으로 진행됩니다.</p>
          <figure>
            <img src="/images/sub_straining_procedure.png" alt="모의 훈련 진행 절차" className="img-pc" />
            <img src="/images/sub_straining_procedure-m.png" alt="모의 훈련 진행 절차" className="img-m" />
            <img src="/images/sub_straining_procedure-mm.png" alt="모의 훈련 진행 절차" className="img-mm" />
          </figure>
        </div>
      </section>
      <section
        className="stInquiry"
        ref={(element) => {
          tabRef.current[4] = element;
        }}
      >
        <form onSubmit={onSubmit}>
          <div className="w1200">
            <h2>악성 메일 모의 훈련 문의</h2>
            <p>아래 내용을 작성해주시면 확인 후 신속하게 연락드리겠습니다.</p>
            <div className="inputForm">
              <div className="noflex">
                <div className="flex">
                  <label>
                    <span className="rTitle">기업명</span>
                    <input
                      type="text"
                      name="corName"
                      title="기업명"
                      ref={(el) => (inputRef.current[0] = el)}
                      value={values.corName}
                      onChange={handleChange}
                      placeholder="기업명을 입력해 주세요."
                    />
                  </label>
                  <label>
                    <span className="rTitle">회사 규모</span>
                    <input
                      type="text"
                      name="qSize"
                      title="회사 규모"
                      ref={(el) => (inputRef.current[1] = el)}
                      value={values.qSize}
                      onChange={handleChange}
                      placeholder="직원 수를 입력해 주세요"
                    />
                  </label>
                </div>
                <div className="flex">
                  <label>
                    <span className="rTitle">담당자명</span>
                    <input
                      type="text"
                      name="mngName"
                      title="담당자명"
                      ref={(el) => (inputRef.current[2] = el)}
                      value={values.mngName}
                      onChange={handleChange}
                      placeholder="담당자분의 성함을 입력해 주세요."
                    />
                  </label>
                  <label>
                    <span className="rTitle">직함</span>
                    <input
                      type="text"
                      name="manage"
                      title="직함"
                      ref={(el) => (inputRef.current[3] = el)}
                      value={values.manage}
                      onChange={handleChange}
                      placeholder="담당자분의 직함을 입력해 주세요."
                    />
                  </label>
                </div>
                <div className="flex">
                  <label>
                    <span className="rTitle">연락처</span>
                    <input
                      type="text"
                      name="phone"
                      title="연락처"
                      ref={(el) => (inputRef.current[4] = el)}
                      value={rgNumWithCommas(values.phone.replace(/[^0-9]/g, ''))}
                      onChange={handleChange}
                      maxLength="11"
                      placeholder="연락 받으실 번호를 입력해 주세요."
                    />
                  </label>
                  <label>
                    <span className="rTitle">이메일</span>
                    <input
                      type="text"
                      name="email"
                      title="이메일"
                      ref={(el) => (inputRef.current[5] = el)}
                      value={values.email}
                      onChange={handleChange}
                      placeholder="이메일을 입력해 주세요."
                    />
                  </label>
                </div>
                <div className="textArea">
                  <textarea
                    name="contents"
                    title="검토사항"
                    ref={(el) => (inputRef.current[6] = el)}
                    value={values.contents}
                    onChange={handleChange}
                    placeholder="모의훈련에 대해 주요하게 검토 중이신 사항에 대하여 입력해 주세요."
                  />
                </div>
              </div>
            </div>
            <PersonalInformation privacyChk={privacyChk} setPrivacyChk={setPrivacyChk} />
            <div className="btn">
              <button type="submit" className="apply">
                문의하기
              </button>
            </div>
          </div>
        </form>
      </section>
      {downloadConfirm && <ConfirmToTraining setDownloadConfirm={setDownloadConfirm} />}
    </>
  );
};

export default SimulatedTraining;
