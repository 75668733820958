import React, { useState } from 'react';
import removeTags from '../../../../hooks/useRemoveTag';

const ContentItem = ({ info }) => {
  const [contentToggle, setContentToggle] = useState(false);
  return (
    <>
      <li onClick={() => setContentToggle(!contentToggle)}>
        <p className="w555">
          {info.category === 'E' && 'EG-Platform'}
          {info.category === 'R' && '수신보안 '}
          {info.category === 'SD' && '발신보안 '}
          {info.category === 'EL' && ' EG-Linker'}
          {info.category === 'O' && '기타'}
          {info.category === 'S' && 'SECU'}
        </p>
        <div className={contentToggle ? 'faqcontent active' : 'faqcontent'}>
          <div>
            <p className="divide">Q</p>
            <p>
              {info.category === 'E' && 'EG-Platform'}
              {info.category === 'R' && '수신보안 '}
              {info.category === 'SD' && '발신보안 '}
              {info.category === 'EL' && ' EG-Linker'}
              {info.category === 'O' && '기타'}
              {info.category === 'S' && 'SECU'}
            </p>
            <p className="question">{info.question}</p>
          </div>
          <div className="drop-down"></div>
        </div>
        {contentToggle && (
          <div className="answer">
            <p className="divide">A</p>
            <p>{removeTags(info.answer)}</p>
          </div>
        )}
      </li>
    </>
  );
};

export default ContentItem;
