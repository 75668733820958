import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import ProductInq from './Components/ProductInQ/productInq';
import PartnershipInq from './Components/PartnershipInQ/partnershipInq';
import DownLoad from './Components/DownLoad/downLoad';
import Faq from './Components/FAQ/Faq';
import Search from './Search';

const CustomerSupport = () => {
  const { pathname } = useLocation();
  const [selectedTab, setSelectedTab] = useState('product');

  useEffect(() => {
    if (pathname === '/customer/product-inquiry') {
      setSelectedTab('product');
    }
    if (pathname === '/customer/partnership-inquiry' || pathname === '/customer/partnership-inquiry/contact-us') {
      setSelectedTab('partnership');
    }
    if (pathname === '/customer/download') {
      setSelectedTab('downLoad');
    }
    if (pathname === '/customer/faq') {
      setSelectedTab('FAQ');
    }
  }, [pathname]);

  return (
    <>
      <section className="customerSupport">
        <div className="container">
          <h2 className="pageTitle">
            {selectedTab === 'product' && '제품문의'}
            {selectedTab === 'partnership' && '제휴문의'}
            {selectedTab === 'downLoad' && '다운로드'}
            {selectedTab === 'FAQ' && 'FAQ'}
          </h2>
          <p className="proposal">
            {selectedTab === 'product' && '제품에 대한 문의는 담당자가 확인 후 신속하고 정확하게 회신하여 드립니다.'}
            {selectedTab === 'partnership' && '새롭고 다양한 제휴기회를 기다리고 있습니다. 기원테크와 함께 비즈니스를 시작해 보세요.'}
            {selectedTab === 'downLoad' && '이지플랫폼의 제품 제안서, 브로슈어 다운로드 신청 페이지입니다.'}
            {selectedTab === 'FAQ' && '제품별로 선택 및 검색이 가능한 자주 묻는 질문 페이지 입니다.'}
          </p>
          <ul className="csMenu">
            <li>
              <NavLink to="/customer/product-inquiry">제품문의</NavLink>
            </li>
            <li>
              <NavLink to="/customer/partnership-inquiry">제휴문의</NavLink>
            </li>
            <li>
              <NavLink to="/customer/download">다운로드</NavLink>
            </li>
            <li>
              <NavLink to="/customer/faq">FAQ</NavLink>
            </li>
          </ul>
          {selectedTab === 'product' && <ProductInq />}
          {selectedTab === 'partnership' && <PartnershipInq />}
          {selectedTab === 'downLoad' && <DownLoad />}
          {selectedTab === 'FAQ' && <Faq />}
        </div>
      </section>
    </>
  );
};

export default CustomerSupport;
