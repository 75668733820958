export const Bebefits = [
  {
    id: 1,
    case: '혜택 01',
    title: '영업 보호권\n' + '우선 제공',
    detail: '기원테크 공식 파트너사가\n' + '영업 중인 고객사에 대한\n' + '영업 보호권을 우선 제공합니다.',
  },
  {
    id: 2,
    case: '혜택 02',
    title: '이벤트·프로모션 가격\n' + '우선 적용',
    detail: '기원테크 공식 파트너사에게\n' + '프로모션 및 이벤트 행사소식을\n' + '우선적으로 공지하고, 프로모션\n' + '가격으로 제품을 제공합니다.',
  },
  {
    id: 3,
    case: '혜택 03',
    title: '영업용 자료 및 지원\n' + '프로그램 제공',
    detail: '기원테크 공식 파트너사에게\n' + '영업 교육 자료 및 최신 보안 이슈\n' + '자료를 지원하고, 맞춤형 지원\n' + '프로그램을 제공합니다.',
  },
  {
    id: 4,
    case: '혜택 04',
    title: '기원테크\n' + '전문인력 투입',
    detail: '파트너사가 고객사 소개 및\n' + '계약에 집중하도록, 제품 설명 및\n' + '중간점검, 제품 설치 및 유지보수는\n' + '기원테크의 전문 인력이 제공합니다.',
  },
];
