import React, { useEffect } from 'react';

const TermEmail = () => {
  useEffect(() => {
    document.querySelector('header').style.display = 'none';
    document.querySelector('footer').style.display = 'none';
  }, []);
  return (
    <section>
      <div className="popup_con">
        <p className="terms_title">이메일무단수집거부</p>

        <p className="terms_text">
          ㈜기원테크는 본 웹사이트에서 게시된 이메일 주소가 전자우편 수집프로그램이나 그 밖의 기술적 장치를 이용하여 수집되는 것을 거부합니다.
          <br />
          이를 위반 시 ‘정보통신망 이용촉진 및 정보보호’ 등에 의해 처벌받을 수 있습니다.
        </p>

        <p className="terms_text_number mb30">&lt;정보통신망 이용촉진 및 정보보호 등에 관한 법률&gt;</p>

        <p className="terms_text_underText">제50조(영리목적의 광고성 정보 전송 제한)</p>

        <p className="terms_text_list">
          ⑤ 전자적 전송매체를 이용하여 영리목저의 광고서 정보를 전송하는 자는 다음 각 호의 어느 하나의 해당하는 조치를 하여서는 아니된다.
        </p>

        <p className="terms_subTitle">3. 영리목적의 광고성 정보를 전송할 목적으로 전화번호 또는 전자우편주소를 자동으로 등록하는 조치</p>

        <p className="terms_text_underText">제74조(벌칙)</p>

        <p className="terms_text_list">① 다음 각호의 어느 하나에 해당하는 자는 1년 이하의 징역 또는 1천만원 이하의 벌금에 처한다.</p>

        <p className="terms_subTitle">4. 제50조 제5항을 위한하여 조치를 한 자</p>
      </div>
    </section>
  );
};

export default TermEmail;
