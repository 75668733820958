import React, { useEffect, useRef, useState } from 'react';
import { functionData } from './functionData';
import { Controller, Scene } from 'react-scrollmagic';

const EgLinker = () => {
  const tabRef = useRef([]);
  const [tab, setTab] = useState(0);
  const [isFixed, setIsFixed] = useState(false);
  const [selectBtn, setSelectBtn] = useState('');

  const handleScroll = () => {
    if (window.scrollY > 450) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }

    if (tabRef.current[0].offsetTop - 300 <= window.scrollY) {
      setSelectBtn('system');
    }
    if (tabRef.current[1].offsetTop - 300 <= window.scrollY) {
      setSelectBtn('eglinker');
    }
    if (tabRef.current[2].offsetTop - 300 <= window.scrollY) {
      setSelectBtn('function');
    }
    if (tabRef.current[3].offsetTop - 300 <= window.scrollY) {
      setSelectBtn('recommend');
    }
    if (tabRef.current[0].offsetTop - 300 > window.scrollY) {
      setSelectBtn('');
    }
  };

  const moveToArea = (index) => {
    tabRef?.current[index]?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      // inline: 'start',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isFixed]);
  return (
    <>
      <section className="inboundSecu emlSub">
        <Controller>
          <Scene triggerElement="#triggerPoint00" classToggle="active_mv">
            <div className="container" id="triggerPoint00">
              <p className="engTitle">Email Gateway Linker</p>
              <h2>EML 재조합 솔루션</h2>
              <p>
                EML 내 컨텐츠 요소를 분리하여 EG-Linker에 <br className="w880" /> 저장한 후,
                <br className="w1920" />
                운영 정책에 맞게 재조합하는 <br className="w880" /> 차세대 EML 재조합 솔루션입니다.
              </p>
            </div>
          </Scene>
        </Controller>
      </section>
      <section
        className="egSystem"
        ref={(element) => {
          tabRef.current[0] = element;
        }}
      >
        <div className={isFixed ? 'menuPart fixed' : 'menuPart'}>
          <ul className="tabMenu w490">
            <li className={selectBtn === 'system' ? 'active' : ''}>
              <button
                type="button"
                onClick={() => {
                  moveToArea(0);
                }}
              >
                기능체계
              </button>
            </li>
            <li className={selectBtn === 'eglinker' ? 'active' : ''}>
              <button
                type="button"
                onClick={() => {
                  moveToArea(1);
                }}
              >
                이지링커
              </button>
            </li>
            <li className={selectBtn === 'function' ? 'active' : ''}>
              <button
                type="button"
                onClick={() => {
                  moveToArea(2);
                }}
              >
                주요기능
              </button>
            </li>
            <li className={selectBtn === 'recommend' ? 'active' : ''}>
              <button
                type="button"
                onClick={() => {
                  moveToArea(3);
                }}
              >
                추천기업
              </button>
            </li>
          </ul>
        </div>
        <div className="container">
          <h2>EML 재조합 솔루션이란? </h2>
          <p>
            망연계 네트워크 환경에서도 외부 사용자와 이메일 컨텐츠를 원활하게 주고 받을 수 있도록
            <br />
            EML 내 컨텐츠 요소를 분리하여 <span className="bold">EG-Linker</span>에 저장한 후, 운영 정책에 맞게 재조합하여 사용자에게 전달하는 기술입니다.
          </p>
          <figure>
            <img src="/images/eglinker_diagram_1920.png" alt="EML 재조합 솔루션 구조도" className="img-inbound_pc" />
            <img src="/images/eglinker_diagram_800.png" alt="기능체계" className="img-inbound_m2" />
            <img src="/images/eglinker_diagram_400.png" alt="기능체계" className="img-inbound_mm2" />
          </figure>
        </div>
      </section>
      <section
        className="egLinker"
        ref={(element) => {
          tabRef.current[1] = element;
        }}
      >
        <div className="container flex">
          <div className="titBox">
            <h2>
              WHAT’S
              <br />
              <span className="purple"> EG-Linker</span>
            </h2>
            <p>
              망분리 환경에서도 EML재조합을 통해
              <br />
              모든 이메일 컨텐츠를 주고 받을 수 있습니다.
            </p>
          </div>
          <ul className="itemBox flex">
            <li>
              <h3>대용량 첨부파일 수·발신</h3>
              <p>
                대용량 첨부파일을 변환해
                <br />
                용량 제한 없이 수 · 발신
              </p>
            </li>
            <li>
              <h3>메일서버 보호</h3>
              <p>
                외부 사용자가 외부 EG-Linker에
                <br />
                접근하여 첨부파일 다운로드
              </p>
            </li>
            <li>
              <h3>첨부파일 관리 및 회수 </h3>
              <p>
                첨부파일 통합 관리,
                <br />
                링크 비활성화로 첨부파일 회수
              </p>
            </li>
          </ul>
        </div>
      </section>
      <section
        className="mainFunction"
        ref={(element) => {
          tabRef.current[2] = element;
        }}
      >
        <div className="w1320">
          <h2>EG-Linker 주요 기능</h2>
          <ul className="flex">
            {functionData.map((it, idx) => {
              return (
                <li
                  className={tab === idx ? 'tabBtn on' : 'tabBtn'}
                  onClick={() => {
                    setTab(idx);
                  }}
                >
                  {it.tabTitle}
                </li>
              );
            })}
          </ul>
          <div className="tabContent">
            {functionData[tab].content.map((its) => {
              return (
                <div className="tabData flex">
                  <div className="titBox">
                    <h3>{its.conTitle}</h3>
                    <p>{its.contDes}</p>
                    <figure className="b-img-pc">
                      <img src={its.src2} alt="" />
                    </figure>
                  </div>
                  <figure>
                    <img src={its.src1} alt="" className="b-img-pc" />
                    <img src={its.src1_m} alt="" className="b-img-m" />
                    <img src={its.src1_mm} alt="" className="b-img-mm" />
                  </figure>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section
        className="egRecommend"
        ref={(element) => {
          tabRef.current[3] = element;
        }}
      >
        <div className="w1320">
          <h2>
            <span className="bold">EML 재조합 솔루션,</span> 이런 기업에게 추천합니다!
          </h2>
          <ul className="flex">
            <li>
              <p>
                망분리 환경으로
                <br />
                대용량 첨부파일 <span className="red">외부 반출 불가 </span>
              </p>
              <p>
                내·외부망에 각각 EG-Linker를
                <br />
                설치하여 <span className="blue">망을 연계</span>, 외부 수신자도
                <br />
                대용량 첨부파일
                <span className="blue"> 다운로드 가능</span>
              </p>
            </li>
            <li>
              <p>
                반출되는 모든 첨부파일에 대한
                <br />
                제대로 된 <span className="red">사후관리 불가</span>
              </p>
              <p>
                첨부파일 <span className="blue">다운로드 시간 및 IP 확인</span>과<br />
                다운로드 링크 비활성화를 통한
                <br />
                <span className="blue">첨부파일 회수</span>로 사후관리가 가능
              </p>
            </li>
            <li>
              <p>
                대용량 첨부파일에 대한
                <br />
                <span className="red">추적 및 이력 관리 불가</span>
              </p>
              <p>
                <span className="blue"> EML 재조합 기능</span>으로 반출되는
                <br />
                대용량 첨부파일을 일반 첨부파일로
                <br />
                변환하여
                <span className="blue"> 추적·이력 관리 가능</span>
              </p>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default EgLinker;
