export const SlideData = [
  {
    id: 1,
    case: 'case 1',
    comment: '대량 스팸메일 공격에 대응하고, 원활한 이메일 업무를 진행하세요.',
    datail:
      '대량 스펨메일 공격으로 인한 잦은 메일 서버 과부화 때문에 EG-Platform을 도입한 후, 이전에 악성 메일로 들어온 메일은 네트워크 단계에서 사전에 차단해 부하가 걸리지 않을 메일 양만 수신 받아 업무가 원활해졌습니다.',
  },
  {
    id: 2,
    case: 'case 2',
    comment: '거래처를 사칭한 사기메일,\n' + '2차 피해를 막았어요.',
    datail:
      '거래처와 유사한 도메인을 사용한 사기메일로 큰 금전 피해를 입어 EG-Platform을 도입하게 되었습니다. 도입 후 ‘유사도메인‘ 사기메일 검출 덕분에 더 이상의 피해는 막을 수 있었습니다.',
  },
  {
    id: 3,
    case: 'case 3',
    comment: '새로운 형태로 들어온 악성코드에 대응할 수 있었어요.',
    datail:
      '패턴에 등록되지 않은 새로운 악성 공격 시도가 자주 포착되고 있었습니다. EG-Platform 도입 후, 등록된 악성 공격 뿐만 아니라 새로운 형태의 악성 공격에 대응하여 안전한 이메일 사용이 가능해졌습니다.',
  },
  {
    id: 4,
    case: 'case 4',
    comment: '외부로 메일 발송 시, 한번 더 체크해서 안전하게 발송할 수 있었어요.',
    datail:
      '거래처의 도메인을 유사하게 위조한 사기메일 공격자에게 회신하여 2차 피해를 입게되어 EG-Platform 도입 후, 악성 공격 이력이 있는 공격자에게 회신 시 경고 알림 덕분에 회신으로 인한 2,3차 피해를 예방할 수 있었습니다.',
  },
  {
    id: 5,
    case: 'case 5',
    comment: '실수로 발생할 수 있는 오발송 사고, 반드시 대비하세요',
    datail:
      '저희 회사는 견적서와 개인정보는 꼭 보안 메일로 발송합니다. 오발송 시 수신자의 본문 보기 제한으로 안전하게 회수할 수 있기 때문입니다. 이미 발송한 메일도 관리할 수 있어 아주 유용합니다.',
  },
  {
    id: 6,
    case: 'case 6',
    comment: '대용량 첨부파일을 발송하고, 관리도 할 수 있어 업무 효율 향상',
    datail:
      '보안 때문에 망분리 환경에서는 대용량 파일을 발송할 수 없어 너무 불편했어요. 하지만 ‘이지플랫폼‘ 덕분에 발송도 문제 없고, 다운로드 제한도 가능해 보안과 편의를 모두 잡았어요. 같은 고민이라면 꼭 도입하세요!',
  },
];
