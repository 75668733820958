import React, { useEffect, useRef, useState } from 'react';
import { Controller, Scene } from 'react-scrollmagic';

const OutgoingSecurity = () => {
  const secRef = useRef([]);
  const [isFixed, setIsFixed] = useState(false);
  const [selectedBtn, setSelectedBtn] = useState('');

  const handleScroll = () => {
    if (window.scrollY > 400) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }

    if (secRef.current[0].offsetTop - 300 <= window.scrollY) {
      setSelectedBtn('system');
    }
    if (secRef.current[1].offsetTop - 300 <= window.scrollY) {
      setSelectedBtn('send');
    }
    if (secRef.current[2].offsetTop - 300 <= window.scrollY) {
      setSelectedBtn('recommend');
    }
    if (secRef.current[3].offsetTop - 300 <= window.scrollY) {
      setSelectedBtn('certification');
    }
    if (secRef.current[0].offsetTop - 300 > window.scrollY) {
      setSelectedBtn('');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isFixed]);

  const moveToArea = (index) => {
    secRef?.current[index]?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      // inline: 'start',
    });
  };

  return (
    <>
      <section className="inboundSecu out">
        <Controller>
          <Scene triggerElement="#triggerPoint00" classToggle="active_mv">
            <div className="container" id="triggerPoint00">
              <p className="engTitle">Outbound Email Security</p>
              <h2>발신 보안 솔루션</h2>
              <p>
                메일을 발송하는 순간부터 발송한 후까지 관리하여,
                <br />
                기업정보가 외부로 유출되는 것을 방지할 수 있는 <br className="w800" /> 솔루션입니다.
              </p>
            </div>
          </Scene>
        </Controller>
      </section>
      <section
        className="outgoingSystem"
        ref={(element) => {
          secRef.current[0] = element;
        }}
      >
        <div className={isFixed ? 'menuPart fixed' : 'menuPart'}>
          <ul className="tabMenu w490">
            <li className={selectedBtn === 'system' ? 'active' : ''}>
              <button
                type="button"
                onClick={() => {
                  moveToArea(0);
                }}
              >
                기능체계
              </button>
            </li>
            <li className={selectedBtn === 'send' ? 'active' : ''}>
              <button
                type="button"
                onClick={() => {
                  moveToArea(1);
                }}
              >
                센드가드
              </button>
            </li>
            <li className={selectedBtn === 'recommend' ? 'active' : ''}>
              <button
                type="button"
                onClick={() => {
                  moveToArea(2);
                }}
              >
                추천기업
              </button>
            </li>
            <li className={selectedBtn === 'certification' ? 'active' : ''}>
              <button
                type="button"
                onClick={() => {
                  moveToArea(3);
                }}
              >
                기술인증
              </button>
            </li>
          </ul>
        </div>
        <div className="w1570">
          <h2>발신 메일 보안 솔루션이란?</h2>
          <p>
            <span className="bold">SendGUARD</span>를 통해 메일 발송 시점부터 발송 후까지 전 과정 관리를 통해
            <br />
            기업 정보 유출을 방지하고 인사 데이터 연동을 통한 승인정책으로 편리함까지 더한 솔루션 입니다.
          </p>
          <figure>
            <img src="/images/sub_outgoing_map.png" alt="기능체계" className="img-inbound_pc" />
            <img src="/images/sub_outgoing_map_m.png" alt="기능체계" className="img-inbound_m" />
            <img src="/images/sub_outgoing_map_mm.png" alt="기능체계" className="img-inbound_mm" />
          </figure>
        </div>
      </section>
      <section
        className="outgoingSpam"
        ref={(element) => {
          secRef.current[1] = element;
        }}
      >
        <div className="w1320 flex">
          <div className="titBox">
            <h2>
              WHAT’S
              <br />
              <span className="darkBlue">SendGUARD</span>
            </h2>
            <p>
              정보 유출을 방지하는 발신 메일 관리,
              <br />
              첨부파일의 안전한 관리와 편의성을 제공합니다.
            </p>
          </div>
          <div className="gridContainer">
            <div className="item">
              <h3>내부정보 유출 보안</h3>
              <p>
                필터링 설정으로
                <br />
                정보 유출 시도 차단
              </p>
            </div>
            <div className="item">
              <h3>승인 메일</h3>
              <p>
                승인 체계를 구축해 팀원의
                <br />
                발신 메일 관리 · 감독
              </p>
            </div>
            <div className="item">
              <h3>보안 메일 회수</h3>
              <p>
                수신자의 메일 열람 이력
                <br />
                확인 및 열람 제한
              </p>
            </div>
            <div className="item">
              <h3>악성 메일 회신 방지</h3>
              <p>
                수신 솔루션과 데이터를 공유,
                <br />
                악성 메일에 답장 시 발신 경고
              </p>
            </div>
            <div className="item">
              <h3>URL 자동 변환</h3>
              <p>
                SendGUARD 장비 링크로
                <br />
                URL을 자동 변환해 링크 공유
              </p>
            </div>
            <div className="item">
              <h3>첨부파일 제어</h3>
              <p>
                쉽고 편리한 일반 · 대용량
                <br />
                첨부파일 사후관리
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        className="outgoingRecommend"
        ref={(element) => {
          secRef.current[2] = element;
        }}
      >
        <div className="w1320">
          <h2>
            <span className="bold">발신 보안 솔루션, </span> 이런 기업에게 추천합니다!
          </h2>
          <ul className="flex">
            <li>
              <p>
                신기술이 이메일로 인해
                <br />
                <span className="red">외부로 유출 </span>될 우려가 되는 기업
              </p>
              <p>
                SendGUARD로&nbsp;
                <span className="blue">
                  발송 정책 <br /> 수립
                </span>
                과 <span className="blue"> 첨부파일 관리 가능</span>
              </p>
            </li>
            <li>
              <p>
                중요한 정보가 있는 메일을
                <br />
                <span className="red">오발송하여 회수 </span>하고 싶었던 기업
              </p>
              <p>
                SendGUARD로&nbsp;
                <span className="blue">
                  발신 지연시간
                  <br />
                  설정
                </span>
                &nbsp;및 <span className="blue"> 보안 메일로 회수!</span>
              </p>
            </li>
            <li>
              <p>
                팀원의 <span className="red">메일을 관리·감독 </span>하여
                <br />
                발송하고 싶은 기업
              </p>
              <p>
                SendGUARD의 <span className="blue">승인 메일 기능</span>
                <br />
                으로 발신 메일 확인 가능!
              </p>
            </li>
          </ul>
        </div>
      </section>
      <section
        className="outgoingCertify"
        ref={(element) => {
          secRef.current[3] = element;
        }}
      >
        <div className="w1320">
          <h2>
            <span className="blue">EG-Platform</span>인 이유
          </h2>
          <p>이메일을 제일 잘 아는 전문가가 만든 단 하나뿐인 Email Security Solution 입니다.</p>
          <ul className="flex">
            <li>
              <h3>
                국내 최초
                <br />
                Gartner 보고서 등재
              </h3>
              <figure>
                <img src="/images/sub_inbound_certify01.png" alt="" />
              </figure>
            </li>
            <li>
              <h3>
                국내 최다 30건
                <br />
                메일 관련 특허 보유
              </h3>
              <figure>
                <img src="/images/sub_inbound_certify02.png" alt="" />
              </figure>
            </li>
            <li>
              <h3>
                이메일 공격 차단
                <br />
                국내 보안 표준으로 채택
              </h3>
              <figure>
                <img src="/images/sub_inbound_certify03.png" alt="" />
                <img src="/images/sub_inbound_certify04.png" alt="" />
              </figure>
            </li>
          </ul>
        </div>
      </section>
      <section></section>
    </>
  );
};

export default OutgoingSecurity;
