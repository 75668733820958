import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import AlterDate from '../../../../hooks/useAlterDate';
import removeTags from '../../../../hooks/useRemoveTag';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';

const SecIssueDetail = () => {
  const { pathname } = useLocation();
  const [detail, setDetail] = useState('');
  const [prevPage, setPrevPage] = useState('');
  const [nextPage, setNextPage] = useState('');
  const idx = pathname.split('/')[4];

  useLayoutEffect(() => {
    const getDetail = async () => {
      const response = await axios.get(`/board/issue?boardType=t&idx=${idx}`);
      setDetail(response.data.board);
      setNextPage(response.data.nextBoardInfo);
      setPrevPage(response.data.prevBoardInfo);
    };
    getDetail();
  }, [pathname]);

  if (!detail) return false;
  return (
    <div className="w1200">
      {!detail ? (
        <div>Loading</div>
      ) : (
        <>
          <div className="datailPage">
            <div className="title">
              <h2>{detail.title}</h2>
              <span className="date">{AlterDate(detail.rgsde)}</span>
            </div>
            <div className="contentItem">
              <p className="content" dangerouslySetInnerHTML={{ __html: detail.contents }}></p>
              {detail.articleUrl && (
                <p className="link">
                  [기사 원문]
                  <Link to={detail.articleUrl} target="_blank">
                    {detail.articleUrl}
                  </Link>
                </p>
              )}
            </div>
          </div>
          <div className="direction">
            {detail.prevIdx !== 0 ? (
              <div className="leftArrow">
                <Link to={`/news/security-issue/detail/${detail.prevIdx}`} style={{ pointerEvents: detail.prevIdx === 0 ? 'none' : '' }}>
                  <span>
                    <BsArrowLeft />
                  </span>
                  <span>이전글</span>
                </Link>
              </div>
            ) : (
              <div className="leftArrow leftArrowNone">
                <Link to={`/news/security-issue/detail/${detail.prevIdx}`} style={{ pointerEvents: detail.prevIdx === 0 ? 'none' : '' }}>
                  <span>
                    <BsArrowLeft />
                  </span>
                  <span>이전글</span>
                </Link>
              </div>
            )}
            <div className="centerList">
              <Link to="/news/security-issue">
                <div className="listIcon">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <p>List</p>
              </Link>
            </div>
            {detail.nextIdx !== 0 ? (
              <div className="rightArrow">
                <Link to={`/news/security-issue/detail/${detail.nextIdx}`} style={{ pointerEvents: detail.nextIdx === 0 ? 'none' : '' }}>
                  <span>다음글</span>
                  <span>
                    <BsArrowRight />
                  </span>
                </Link>
              </div>
            ) : (
              <div className="rightArrow rightArrowNone">
                <Link to={`/news/security-issue/detail/${detail.nextIdx}`} style={{ pointerEvents: detail.nextIdx === 0 ? 'none' : '' }}>
                  <span>다음글</span>
                  <span>
                    <BsArrowRight />
                  </span>
                </Link>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SecIssueDetail;
