export const NavData = [
  {
    id: 1,
    title: 'EG-Platform',
    link: '/eg-platform',
    arr: [
      { id: 1, title: '보안이슈', link: '/eg-platform/security-issue' },
      { id: 2, title: '기능체계', link: '/eg-platform/functional-system' },
      { id: 3, title: '특장점', link: '/eg-platform/features' },
      { id: 4, title: '지원혜택', link: '/eg-platform/support-benefits' },
      { id: 5, title: '도입형태', link: '/eg-platform/introduction-form' },
      { id: 6, title: '도입고객사', link: '/eg-platform/introduction-company' },
    ],
  },
  {
    id: 2,
    title: '제품구성',
    link: '/product-composition/receiving-security',
    arr: [
      { id: 1, title: '수신보안', link: '/product-composition/receiving-security' },
      { id: 2, title: '발신보안', link: '/product-composition/outgoing-security' },
      /*      { id: 3, title: '이지링커', link: '/product-composition/EG-Linker' },*/
      { id: 3, title: 'CC인증', link: '/product-composition/CC-certification' },
    ],
  },
  {
    id: 4,
    title: '기술인증',
    link: '/technology-certification',
    arr: [
      { id: 1, title: 'CC인증', link: '/technology-certification/cc-certification' },
      { id: 2, title: '가트너인증', link: '/technology-certification/gartner-certification' },
      { id: 3, title: '인증현황', link: '/technology-certification/certification-status' },
      { id: 4, title: '국제표준', link: '/technology-certification/international-standard' },
    ],
  },
  {
    id: 5,
    title: '모의훈련',
    link: '/simulated-training',
    arr: [
      { id: 1, title: '추진개요', link: '/simulated-training/promotion-outline' },
      { id: 2, title: '추진필요성', link: '/simulated-training/necessity' },
      { id: 3, title: '훈련과정', link: '/simulated-training/training-course' },
      { id: 4, title: '진행절차', link: '/simulated-training/proceedings' },
      { id: 5, title: '신청하기', link: '/simulated-training/inquiry' },
    ],
  },
  {
    id: 6,
    title: '고객지원',
    link: '/customer/product-inquiry',
    arr: [
      { id: 1, title: '제품문의', link: '/customer/product-inquiry' },
      { id: 2, title: '제휴문의', link: '/customer/partnership-inquiry' },
      { id: 3, title: '다운로드', link: '/customer/download' },
      { id: 4, title: 'FAQ', link: '/customer/faq' },
    ],
  },
  {
    id: 7,
    title: '뉴스',
    link: '/news/news',
    arr: [
      { id: 1, title: '뉴스', link: '/news/news' },
      { id: 2, title: '보안이슈', link: '/news/security-issue' },
      { id: 3, title: '뉴스레터', link: '/news/newsletter' },
    ],
  },
];
