import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { useSearchParams } from 'react-router-dom';

const Search = () => {
  const selectRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [val, setVal] = useState('');
  const [text, setText] = useState('전체');
  const [searchText, setSearchText] = useState('');
  const [selectToggle, setSelectToggle] = useState(false);

  const onClickVal = useCallback(
    (v, t) => {
      setVal(v);
      setText(t);
      setSelectToggle(false);
    },
    [val, text],
  );

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      searchParams.set('pageNo', 1);
      // searchParams.set('searchType', val)
      val ? searchParams.set('searchType', val) : searchParams.set('searchType', null);
      searchText ? searchParams.set('searchText', searchText) : searchParams.delete('searchText');
      setSearchParams(searchParams);
    },
    [searchParams, val, searchText, setSearchParams],
  );

  const dropdownCloseHandler = ({ target }) => {
    if (selectToggle && !selectRef?.current?.contains(target)) {
      setSelectToggle(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', dropdownCloseHandler);
    return () => {
      window.addEventListener('click', dropdownCloseHandler);
    };
  });
  return (
    <div className="searchBox flex newsSearch">
      <div className="dropBox">
        <div className={selectToggle ? 'selected active' : 'selected'} onClick={() => setSelectToggle(!selectToggle)} ref={selectRef}>
          <p className={selectToggle ? 'active' : 'text'}>{text}</p>
        </div>
        <ul className={selectToggle ? 'dropDown active' : 'dropDown'}>
          <li onClick={() => onClickVal('', '전체')}>전체</li>
          <li onClick={() => onClickVal('title', '제목')}>제목</li>
          <li onClick={() => onClickVal('contents', '내용')}>내용</li>
        </ul>
      </div>

      <form onSubmit={onSubmit}>
        <div className="searchInput">
          <input
            value={searchText}
            placeholder="검색어를 입력하세요."
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
          <button type="submit" className="searchBtn">
            <FiSearch />
          </button>
        </div>
      </form>
    </div>
  );
};

export default Search;
