import React, { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const Pagination = ({ pagingInfo }) => {
  const [pageParams, setPageParams] = useSearchParams();
  const currentPage = pageParams.get('pageNo');
  const { firstPageNo, finalPageNo, startPageNo, endPageNo, nextPageNo, prevPageNo, pageNo } = pagingInfo;
  let pageItem = [];
  for (let i = startPageNo; i <= endPageNo; i++) {
    pageItem.push(i);
  }

  const onPageChange = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const moveToFirst = useCallback(() => {
    if (currentPage) {
      pageParams.set('pageNo', firstPageNo);
    } else {
      pageParams.append('pageNo', firstPageNo);
    }
    setPageParams(pageParams);
    onPageChange();
  }, [currentPage, firstPageNo, pageParams, setPageParams]);

  const moveToLast = useCallback(() => {
    if (currentPage) {
      pageParams.set('pageNo', finalPageNo);
    } else {
      pageParams.append('pageNo', finalPageNo);
    }
    setPageParams(pageParams);
    onPageChange();
  }, [currentPage, finalPageNo, pageParams, setPageParams]);

  const moveToPrev = useCallback(() => {
    if (currentPage > 1) {
      pageParams.set('pageNo', prevPageNo);
    }
    setPageParams(pageParams);
    onPageChange();
  }, [pageParams, setPageParams, prevPageNo, currentPage]);

  const moveToNext = useCallback(() => {
    if (currentPage < finalPageNo) {
      pageParams.set('pageNo', nextPageNo);
    }
    setPageParams(pageParams);
    onPageChange();
  }, [pageParams, setPageParams, prevPageNo, currentPage, finalPageNo, nextPageNo]);

  const onClickPage = useCallback(
    (id) => {
      if (currentPage) {
        pageParams.set('pageNo', id);
      } else {
        pageParams.append('pageNo', id);
      }
      setPageParams(pageParams);
      onPageChange();
    },
    [currentPage, pageParams, setPageParams],
  );

  return (
    pagingInfo && (
      <ul className="pagination">
        <li>
          <button className="firstPage" onClick={moveToFirst}>
            <img src="/images/pagination_left_01.png" alt="처음으로" />
          </button>
        </li>
        <li>
          <button className="prevPage" onClick={moveToPrev}>
            <img src="/images/pagination_left_02.png" alt="이전으로" />
          </button>
        </li>
        {pageItem.map((it) => (
          <li key={it}>
            <button
              className={it === +pageNo ? 'pageNum active' : 'pageNum'}
              onClick={() => {
                onClickPage(it);
              }}
            >
              {it}
            </button>
          </li>
        ))}
        <li>
          <button className="nextPage" onClick={moveToNext}>
            <img src="/images/pagination_right_02.png" alt="다음으로" />
          </button>
        </li>
        <li>
          <button className="lastPage" onClick={moveToLast}>
            <img src="/images/pagination_right_01.png" alt="맨뒤으로" />
          </button>
        </li>
      </ul>
    )
  );
};

export default Pagination;
