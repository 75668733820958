import React, { useCallback, useEffect, useRef, useState } from 'react';
import Pagination from '../../../../components/pagination/Pagination';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import ContentItem from './contentItem';
import Search from '../../Search';

const Faq = () => {
  const [list, setList] = useState();
  const [pagingInfo, setPagingInfo] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = searchParams.get('pageNo') || 1;
  const searchType = searchParams.get('searchType');
  const searchText = searchParams.get('searchText');
  const searchCategory = searchParams.get('category');
  const [val, setVal] = useState('');

  useEffect(() => {
    const getList = async () => {
      const response = await axios.get(
        `/faq/list?pageNo=${currentPage}${searchType ? `&searchType=${searchType}` : ''}${searchText ? `&searchText=${searchText}` : ''}${
          searchCategory ? `&category=${searchCategory}` : ''
        }`,
      );
      if (response.data.faqList.length !== 0) {
        setList(response.data.faqList);
        setPagingInfo(response.data.paging);
      } else {
        setList([]);
      }
    };
    getList();
  }, [searchParams]);

  const onClickTab = useCallback(
    (v) => {
      setVal(v);
      searchParams.set('pageNo', 1);
      v ? searchParams.set('category', v) : searchParams.delete('category');
      setSearchParams(searchParams);
    },
    [val, searchParams],
  );

  return (
    <div className="faq">
      <div className="container">
        {list?.length !== 0 ? (
          <>
            <div className="menuFlex">
              <Search />
              <ul className="menuBtn">
                <li className={val === '' ? 'active' : ''} onClick={() => onClickTab('')}>
                  <button>전체</button>
                </li>
                <li className={val === 'E' ? 'active' : ''} onClick={() => onClickTab('E')}>
                  <button>EG-Platform</button>
                </li>
                <li className={val === 'R' ? 'active' : ''} onClick={() => onClickTab('R')}>
                  <button>수신보안</button>
                </li>
                <li className={val === 'SD' ? 'active' : ''} onClick={() => onClickTab('SD')}>
                  <button>발신보안</button>
                </li>
                {/*                <li className={val === 'EL' ? 'active' : ''} onClick={() => onClickTab('EL')}>
                  <button>EG-Linker</button>
                </li>*/}
                <li className={val === 'O' ? 'active' : ''} onClick={() => onClickTab('O')}>
                  <button>기타</button>
                </li>
              </ul>
            </div>
            <ul className="faqList">
              {list?.map((val) => (
                <ContentItem info={val} key={val.idx} />
              ))}
            </ul>
          </>
        ) : (
          <>
            <div className="menuFlex">
              <Search />
              <ul className="menuBtn">
                <li className={val === '' ? 'active' : ''} onClick={() => onClickTab('')}>
                  <button>전체</button>
                </li>
                <li className={val === 'E' ? 'active' : ''} onClick={() => onClickTab('E')}>
                  <button>EG-Platform</button>
                </li>
                <li className={val === 'R' ? 'active' : ''} onClick={() => onClickTab('R')}>
                  <button>수신보안</button>
                </li>
                <li className={val === 'SD' ? 'active' : ''} onClick={() => onClickTab('SD')}>
                  <button>발신보안</button>
                </li>
                {/*                <li className={val === 'EL' ? 'active' : ''} onClick={() => onClickTab('EL')}>
                  <button>EG-Linker</button>
                </li>*/}
                <li className={val === 'O' ? 'active' : ''} onClick={() => onClickTab('O')}>
                  <button>기타</button>
                </li>
              </ul>
            </div>
            <p className="noList">리스트가 없습니다.</p>
          </>
        )}
        {list?.length !== 0 && pagingInfo ? <Pagination pagingInfo={pagingInfo} /> : null}
      </div>
    </div>
  );
};

export default Faq;
