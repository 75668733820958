import React, { useEffect, useRef, useState } from 'react';
import { Controller, Scene } from 'react-scrollmagic';

const ReceivingSecurity = () => {
  const secRef = useRef([]);
  const [isFixed, setIsFixed] = useState(false);
  const [selectedBtn, setSelectedBtn] = useState('');

  const handleScroll = () => {
    if (window.scrollY > 400) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }

    if (secRef.current[0].offsetTop - 300 <= window.scrollY) {
      setSelectedBtn('system');
    }
    if (secRef.current[1].offsetTop - 300 <= window.scrollY) {
      setSelectedBtn('spam');
    }
    if (secRef.current[2].offsetTop - 300 <= window.scrollY) {
      setSelectedBtn('receive');
    }
    if (secRef.current[3].offsetTop - 300 <= window.scrollY) {
      setSelectedBtn('recommend');
    }
    if (secRef.current[4].offsetTop - 300 <= window.scrollY) {
      setSelectedBtn('certification');
    }
    if (secRef.current[0].offsetTop - 300 > window.scrollY) {
      setSelectedBtn('');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isFixed]);

  const moveToArea = (index) => {
    secRef?.current[index]?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      // inline: 'start',
    });
  };

  return (
    <>
      <section className="inboundSecu">
        <Controller>
          <Scene triggerElement="#triggerPoint00" classToggle="active_mv">
            <div className="container" id="triggerPoint00">
              <p className="engTitle">Inbound Email Security</p>
              <h2>수신 보안 솔루션</h2>
              <p>
                정상 메일로 위장해 특정 기업을 <br className="w757" /> 표적으로 공격하는
                <br className="w757_none" />
                지능형 지속 공격(APT)에 <br className="w757" />
                선제 대응가능한 솔루션입니다.
              </p>
            </div>
          </Scene>
        </Controller>
      </section>
      <section
        className="system"
        ref={(element) => {
          secRef.current[0] = element;
        }}
      >
        <div className={isFixed ? 'menuPart fixed' : 'menuPart'}>
          <ul className="tabMenu w660">
            <li className={selectedBtn === 'system' ? 'active' : ''}>
              <button
                type="button"
                onClick={() => {
                  moveToArea(0);
                }}
              >
                기능체계
              </button>
            </li>
            <li className={selectedBtn === 'spam' ? 'active' : ''}>
              <button
                type="button"
                onClick={() => {
                  moveToArea(1);
                }}
              >
                스팸가드
              </button>
            </li>
            <li className={selectedBtn === 'receive' ? 'active' : ''}>
              <button
                type="button"
                onClick={() => {
                  moveToArea(2);
                }}
              >
                리시브가드
              </button>
            </li>
            <li className={selectedBtn === 'recommend' ? 'active' : ''}>
              <button
                type="button"
                onClick={() => {
                  moveToArea(3);
                }}
              >
                추천기업
              </button>
            </li>
            <li className={selectedBtn === 'certification' ? 'active' : ''}>
              <button
                type="button"
                onClick={() => {
                  moveToArea(4);
                }}
              >
                기술인증
              </button>
            </li>
          </ul>
        </div>
        <div className="w1320">
          <h2>수신 메일 보안 솔루션이란?</h2>
          <p>
            <span className="bold">SpamGUARD</span>의 학습형 스팸 필터링, 메일 서버 보호 기능과 <span className="bold">ReceiveGUARD</span>의 지능형 사기 메일
            검출 기능을 결합한
            <br />
            솔루션으로 '실시간, 동적, 학습'기능을 통해 능동적인 선제 대응이 가능한 유일무이한 사기 메일 필터 입니다.
          </p>
          <figure>
            <img src="/images/sub_inbound_map.png" alt="기능체계" className="img-inbound_pc" />
            <img src="/images/sub_inbound_map_m.png" alt="기능체계" className="img-inbound_m" />
            <img src="/images/sub_inbound_map_mm.png" alt="기능체계" className="img-inbound_mm" />
          </figure>
        </div>
      </section>
      <section
        className="spamGuard"
        ref={(element) => {
          secRef.current[1] = element;
        }}
      >
        <div className="w1320 flex">
          <div className="titBox">
            <h2>
              WHAT’S
              <br />
              <span className="green"> SpamGUARD</span>
            </h2>
            <p>
              바이러스, 악성코드 등<br />
              스팸 메일을 완벽하게 차단합니다.
            </p>
          </div>
          <ul className="itemBox flex">
            <li>
              <h3>릴레이 서버 사용 방지</h3>
              <p>
                해커의 기업 도메인
                <br />
                무단 이용 차단
              </p>
            </li>
            <li>
              <h3>네트워크 방화벽 기능</h3>
              <p>
                이메일 지능형 방화벽을 통한
                <br />
                대량 메일 공격 차단
              </p>
            </li>
            <li>
              <h3>학습형 스팸 필터링</h3>
              <p>
                학습을 통한 맞춤형
                <br />
                스팸 차단 필터링
              </p>
            </li>
          </ul>
        </div>
      </section>
      <section
        className="receiveGuard"
        ref={(element) => {
          secRef.current[2] = element;
        }}
      >
        <div className="w1320 flex">
          <div className="gridContainer">
            <div className="item">
              <h3>APT 선제 대응</h3>
              <p>
                다중 분석 검사를
                <br />
                통한 선제 대응
              </p>
            </div>
            <div className="item">
              <h3>URL End-Point 검사</h3>
              <p>
                실시간 제로데이 공격 대응
                <br />및 위험 URL 이미지 변환
              </p>
            </div>
            <div className="item">
              <h3>헤더 위 · 변조 검출</h3>
              <p>
                헤더 검사 및 발신 주소 변경
                <br />
                메일 검출로 유효성 체크
              </p>
            </div>
            <div className="item">
              <h3>발송지 추적</h3>
              <p>
                메일 발송지와 경유지를 추적해
                <br />
                발신자 진위 여부 파악
              </p>
            </div>
            <div className="item">
              <h3>유사 도메인 검출</h3>
              <p>
                머신 러닝 기술 기반 거래처와
                <br />
                유사한 사칭 계정 검출
              </p>
            </div>
            <div className="item">
              <h3>신뢰도 검사</h3>
              <p>
                모든 수신 메일 학습과
                <br />
                비교 분석해 메일 진위 여부 판단
              </p>
            </div>
          </div>
          <div className="titBox">
            <h2>
              WHAT’S
              <br />
              <div className="purple"> ReceiveGUARD</div>
            </h2>
            <p>
              기존 이메일 공격과, 알려지지 않은
              <br />
              새로운 사기 메일 공격까지도 완전 차단합니다.
            </p>
          </div>
        </div>
      </section>
      <section
        className="recommend"
        ref={(element) => {
          secRef.current[3] = element;
        }}
      >
        <div className="w1320">
          <h2>
            <span className="bold">수신 보안 솔루션,</span> 이런 기업에게 추천합니다!
          </h2>
          <ul className="flex">
            <li>
              <p>
                <span className="red">대량 메일 공격</span>으로 인한
                <br />
                메일 서버 과부하가 우려되는 기업
              </p>
              <p>
                SpamGUARD로
                <br />
                <span className="blue">대량 메일 공격 대응</span> 가능!
              </p>
            </li>
            <li>
              <p>
                <span className="red">랜섬웨어</span> 혹은 <span className="red">사기 메일</span>로
                <br />
                피해를 입었던 기업
              </p>
              <p>
                ReceiveGUARD로
                <br />
                <span className="blue">메일 APT 공격 대응</span>가능!
              </p>
            </li>
            <li>
              <p>
                해커의 <span className="red">거래처 사칭 메일</span>로
                <br />
                도메인 평판이 낮아진 기업
              </p>
              <p>
                ReceiveGUARD로
                <br />
                유사 도메인 <span className="blue">사칭 메일 탐지</span> 가능!
              </p>
            </li>
          </ul>
        </div>
      </section>
      <section
        className="certification"
        ref={(element) => {
          secRef.current[4] = element;
        }}
      >
        <div className="w1320">
          <h2>
            <span className="blue">EG-Platform</span>인 이유
          </h2>
          <p>이메일을 제일 잘 아는 전문가가 만든 단 하나뿐인 Email Security Solution 입니다.</p>
          <ul className="flex">
            <li>
              <h3>
                국내 최초
                <br />
                Gartner 보고서 등재
              </h3>
              <figure>
                <img src="/images/sub_inbound_certify01.png" alt="" />
              </figure>
            </li>
            <li>
              <h3>
                국내 최다 30건
                <br />
                메일 관련 특허 보유
              </h3>
              <figure>
                <img src="/images/sub_inbound_certify02.png" alt="" />
              </figure>
            </li>
            <li>
              <h3>
                이메일 공격 차단
                <br />
                국내 보안 표준으로 채택
              </h3>
              <figure>
                <img src="/images/sub_inbound_certify03.png" alt="" />
                <img src="/images/sub_inbound_certify04.png" alt="" />
              </figure>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default ReceivingSecurity;
