import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Controller, Scene } from 'react-scrollmagic';

const TechnologyCertification = () => {
  const tabRef = useRef([]);
  const { pathname } = useLocation();
  const [isFixed, setIsFixed] = useState(false);
  const [selectBtn, setSelectBtn] = useState('');

  const handleScroll = () => {
    if (window.scrollY > 400) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }

    if (tabRef.current[0].offsetTop - 300 <= window.scrollY) {
      setSelectBtn('CC');
    }
    if (tabRef.current[1].offsetTop - 300 <= window.scrollY) {
      setSelectBtn('Gartner');
    }
    if (tabRef.current[2].offsetTop - 300 <= window.scrollY) {
      setSelectBtn('status');
    }
    if (tabRef.current[3].offsetTop - 300 <= window.scrollY) {
      setSelectBtn('standard');
    }
    if (tabRef.current[0].offsetTop - 300 > window.scrollY) {
      setSelectBtn('');
    }
  };

  const moveToArea = (index) => {
    tabRef?.current[index]?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const moveToAreaStart = (idx) => {
    tabRef?.current[idx]?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      // inline: 'start',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isFixed]);

  useEffect(() => {
    if (pathname === '/technology-certification') {
      window.scrollTo(0, 0);
    }
    if (pathname === '/technology-certification/cc-certification') {
      moveToArea(0);
      setSelectBtn('CC');
    }
    if (pathname === '/technology-certification/gartner-certification') {
      moveToArea(1);
      setSelectBtn('Gartner');
    }
    if (pathname === '/technology-certification/certification-status') {
      moveToArea(2);
      setSelectBtn('status');
    }
    if (pathname === '/technology-certification/international-standard') {
      moveToArea(3);
      setSelectBtn('standard');
    }
  }, [pathname]);

  return (
    <>
      <section className="inboundSecu tech">
        <Controller>
          <Scene triggerElement="#triggerPoint00" classToggle="active_mv">
            <div className="container" id="triggerPoint00">
              <p className="engTitle">Technical Certification</p>
              <h2>기술인증</h2>
              <p>
                이메일 보안 솔루션, 이지플랫폼은
                <br className="w920" /> 각종 인증을 비롯해,
                <br className="w920_none" />더 나아가 국제 표준까지
                <br className="w920" /> 다양한 기술 인증을 보유하고 있습니다.
              </p>
            </div>
          </Scene>
        </Controller>
      </section>

      <section
        className="ccCertify"
        ref={(element) => {
          tabRef.current[0] = element;
        }}
      >
        <div className={isFixed ? 'menuPart fixed' : 'menuPart'}>
          <ul className="tabMenu w490">
            <li className={selectBtn === 'CC' ? 'active' : ''}>
              <button
                type="button"
                onClick={() => {
                  moveToArea(0);
                }}
              >
                CC인증
              </button>
            </li>
            <li className={selectBtn === 'Gartner' ? 'active' : ''}>
              <button
                type="button"
                onClick={() => {
                  moveToArea(1);
                }}
              >
                가트너인증
              </button>
            </li>
            <li className={selectBtn === 'status' ? 'active' : ''}>
              <button
                type="button"
                onClick={() => {
                  moveToArea(2);
                }}
              >
                인증현황
              </button>
            </li>
            <li className={selectBtn === 'standard' ? 'active' : ''}>
              <button
                type="button"
                onClick={() => {
                  moveToArea(3);
                }}
              >
                국제표준
              </button>
            </li>
          </ul>
        </div>
        <div className="w1320">
          <h2>CC 인증</h2>
          <p>
            ReceiveGUARD V1.1 은 보안 제품에 대한 국제공통평가기준을 만족하며
            <br />
            정보 및 공공기관이 정보보안 제품을 도입할 때 필수적으로 요구되는 CC인증(EAL2)을 획득하였습니다.
          </p>
          <div className="flex">
            <figure>
              <img src="/images/sub_techcertify_receive.png" alt="" />
            </figure>
            <div className="text">
              <span>수신 메인 보안 솔루션</span>
              <h3>Receive GUARD v1.1</h3>
              <p>차세대 메일 보안 방화벽</p>
              <span>
                자체 개발 AI와 이메일 보안의 결합으로 세계 유일
                <br />
                지능형 사기 메일 선제 대응 솔루션
              </span>
            </div>
          </div>
        </div>
      </section>
      <section
        className="gartner"
        ref={(element) => {
          tabRef.current[1] = element;
        }}
      >
        <div className="w1320">
          <h2>가트너 인증</h2>
          <p>기원테크의 이메일 보안 솔루션 벤더 관련 가트너 인증 현황입니다.</p>
          <div className="flex">
            <div className="reportBox">
              <h3>
                <span className="title">Gartner</span>이메일 보안 시장조사 리포트 등재
              </h3>
              <div>
                <p>
                  2019년, 머신러닝과 AI기반으로 선제 대응하여 향후 이메일 보안시장을 선도할 것으로 예상되는
                  <span className="bold">이메일 보안 솔루션 벤더 LIST</span>에 선정되었습니다.
                </p>
                <figure>
                  <img src="/images/sub_gartner_certify01.png" alt="" />
                </figure>
              </div>
            </div>
            <div className="listBox">
              <h3>
                <span className="title">Gartner</span>인증 이메일 보안 벤더 목록 선정
              </h3>
              <div>
                <p>
                  2021년, 기원테크는 가트너 애널리스트가 인증하는 전세계 기술력 우수 이메일 보안 솔루션
                  <span className="bold"> 벤더 목록의 56개 사 중 하나로 선정</span>되었습니다.
                </p>
                <figure>
                  <img src="/images/sub_gartner_certify02.png" alt="" />
                </figure>
              </div>
            </div>
          </div>
          <div className="vendorList">
            <h3>
              <span className="title">Gartner</span>이메일 보안 벤더 목록 선정
            </h3>
            <div>
              <p>
                <span className="bold">2023년</span>, 기원테크는 가트너 애널리스트가 인정하는 <span className="bold">전세계 기술력 우수</span> 이메일 보안
                솔루션 벤더 목록의 <span className="bold">45개사 중 하나로 선정</span>되었습니다.
              </p>
              <figure>
                <img src="/images/sub_gartner_certify03.png" alt="" className="dis-n_m" />
                <img src="/images/sub_gartner_certify03_m.png" alt="" className="dis-b_m" />
                <img src="/images/sub_gartner_certify03_mm.png" alt="" className="dis-b_mm" />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section
        className="situation"
        ref={(element) => {
          tabRef.current[2] = element;
        }}
      >
        <div className="w1320">
          <h2>기술 인증 현황</h2>
          <p>기원테크가 보유하고 있는 다양한 기술 인증 현황입니다.</p>
          <div className="gridContainer">
            <div className="item">
              <h3>GS인증 1등급 획득</h3>
              <div>
                <p>
                  국제 표준에 따라 기능 적합성, 성능 효율성, 사용성, 신뢰성, 보안성을 평가하는
                  <br />
                  GS인증에서 1등급을 획득하였습니다.
                </p>
                <figure>
                  <img src="/images/sub_situation_certify01.png" alt="" />
                </figure>
              </div>
            </div>
            <div className="item">
              <h3>TTA주관 V&V 시험, EG-Platform 모든 시험 항목 합격</h3>
              <div>
                <p>
                  EG-Platform의 기능/성능에 대해 전문시험기관인 TTA가 평가, 전 시험 항목에서
                  <br />
                  ‘합격’ 시험 결과를 받았습니다.
                </p>
                <figure>
                  <img src="/images/sub_situation_certify03.png" alt="" />
                </figure>
              </div>
            </div>
            <div className="item">
              <h3>IT보안인증사무국 CC인증 획득</h3>
              <div>
                <p>
                  ReceiveGUARD v1.1은 보안 제품에 대한 국제 공통평가기준을 만족하여
                  <br />
                  CC인증(EAL2)을 획득하였습니다.
                </p>
                <figure>
                  <img src="/images/sub_situation_certify05.png" alt="" />
                </figure>
              </div>
            </div>
            <div className="item">
              <h3>국내·외 지식 재산 확보 현황</h3>
              <div>
                <figure>
                  <img src="/images/sub_situation_certify07.png" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="standard"
        ref={(element) => {
          tabRef.current[3] = element;
        }}
      >
        <div className="w1200">
          <h2>국제 표준</h2>
          <p>기원테크의 기술은 국내를 넘어 국제 기준으로 나아가고 있습니다.</p>
          <div className="flex">
            <div className="left">
              <h3>TTA, ICT 분야 표준으로 채택</h3>
              <figure>
                <img src="/images/sub_standard_certify01.png" alt="" className="dis-n_m" />
                <img src="/images/sub_standard_certify01-m.png" alt="" className="dis-b_m" />
              </figure>
            </div>
            <div className="right">
              <h3>ITU-T 신규 표준개발과제 등록 진행</h3>
              <figure>
                <img src="/images/sub_standard_certify02.png" alt="" className="dis-n_m" />
                <img src="/images/sub_standard_certify02-m.png" alt="" className="dis-b_m" />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TechnologyCertification;
