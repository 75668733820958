import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { NavData } from './NavData';

const EntireNav = () => {
  const [dep1, setDep1] = useState(0);

  const handleOnClick = (e, idx) => {
    setDep1(idx);
  };

  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => (document.body.style = `overflow: auto`);
  }, []);

  return (
    <div className="hiddenMenu">
      <div className="hiddenLogo">
        <Link to="/" className="logo">
          <img src="/images/logo.png" alt="전체네비 로고" />
        </Link>
      </div>
      <ul className="hiddenGnb">
        {NavData.map((it, idx) => (
          <li key={it.id} className={dep1 === idx ? 'active' : ''} onClick={(e) => handleOnClick(e, idx)}>
            <button type="button" className="gnbList">
              {it.title}
            </button>
            <ul className="hiddenLnb">
              {it.arr.map((its, num) => (
                <li key={its.id} className={dep1 === num ? 'lngList active' : 'lngList'} onClick={(e) => handleOnClick(e, idx)}>
                  <Link to={its.link}>{its.title}</Link>
                </li>
              ))}
            </ul>
            {/*<div className="cover"></div>*/}
          </li>
        ))}
      </ul>

      <div className="h_contact inner">
        <Link to="/customer/product-inquiry/contact-us" className="contit">
          <figure></figure>
        </Link>
      </div>
    </div>
  );
};

export default EntireNav;
