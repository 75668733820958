import React, { useEffect, useLayoutEffect, useState } from 'react';
import { NavLink, Route, Routes, useLocation } from 'react-router-dom';
import Search from './Search';
import NewsDetail from './components/NewsAndSecuriy/newsDetail';
import NewsLetter from './components/NewsLetter/newsLetter';
import NewsLetterDetail from './components/NewsLetter/newsLetterDetail';
import News from './components/NewsAndSecuriy/news';
import SecurityIssue from './components/securityIssue/securityIssue';
import SecIssueDetail from './components/securityIssue/secIssueDetail';

const NewsPages = () => {
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();
  const location = useLocation();
  const [selectTab, setSelectTab] = useState('news');
  const [newsDetailPage, setNewsDetailPage] = useState(false);

  useLayoutEffect(() => {
    setLoading(true);
    setNewsDetailPage(location.pathname.includes('detail'));
  }, [location.pathname]);

  useEffect(() => {
    if (pathname === '/news/news') {
      setSelectTab('news');
    }
    if (pathname === '/news/security-issue') {
      setSelectTab('securityIssue');
    }
    if (pathname === '/news/newsletter') {
      setSelectTab('newsLetter');
    }
  }, [pathname]);

  return (
    <>
      <section className={location.pathname.includes('detail') ? 'newspages detail' : 'newspages'}>
        <div className="container">
          {!newsDetailPage && (
            <>
              <h2 className="pageTitle_news">
                {selectTab === 'news' && '뉴스'}
                {selectTab === 'securityIssue' && '보안이슈'}
                {selectTab === 'newsLetter' && '뉴스레터'}
              </h2>
              <p className="proposal">기원테크와 이지플랫폼의 소식을 가장 빠르게 받아보세요.</p>
              {selectTab === 'news' || selectTab === 'securityIssue' ? (
                <div className="nFlex">
                  <ul className="newsMenu">
                    <li>
                      <NavLink to="/news/news">뉴스</NavLink>
                    </li>
                    <li>
                      <NavLink to="/news/security-issue">보안이슈</NavLink>
                    </li>
                    <li>
                      <NavLink to="/news/newsletter">뉴스레터</NavLink>
                    </li>
                  </ul>
                  <Search />
                </div>
              ) : (
                <div className="nFlex">
                  <ul className="newsMenu">
                    <li>
                      <NavLink to="/news/news">뉴스</NavLink>
                    </li>
                    <li>
                      <NavLink to="/news/security-issue">보안이슈</NavLink>
                    </li>
                    <li>
                      <NavLink to="/news/newsletter">뉴스레터</NavLink>
                    </li>
                  </ul>
                </div>
              )}
            </>
          )}
          <Routes>
            <Route path="/news" element={<News />} />
            <Route path="/news/detail/:id" element={loading && <NewsDetail />} />
            <Route path="/security-issue" element={<SecurityIssue />} />
            <Route path="/security-issue/detail/:id" element={loading && <SecIssueDetail />} />
            <Route path="/newsletter" element={<NewsLetter />} />
            <Route path="/newsletter/detail/:id" element={loading && <NewsLetterDetail />} />
          </Routes>
        </div>
      </section>
    </>
  );
};

export default NewsPages;
