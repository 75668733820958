import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BsArrowRight, BsArrowLeft } from 'react-icons/bs';
import axios from 'axios';
import AlterDate from '../../../../hooks/useAlterDate';
import trTags from '../../../../hooks/useTrTags';

const NewsLetterDetail = () => {
  const { pathname } = useLocation();
  const [detail, setDetail] = useState('');
  const idx = pathname.split('/')[4];

  useEffect(() => {
    const getDetail = async () => {
      const response = await axios.get(`/board/newsletter?boardType=l&idx=${idx}`);
      setDetail(response.data.board);
    };
    getDetail();
  }, [pathname]);

  if (!detail) return false;
  return (
    <div className="w1200">
      {!detail ? (
        <div>Loading</div>
      ) : (
        <>
          <div className="datailPage_letter">
            <div className="title">
              <h2>{detail.title}</h2>
              <p className="date">{AlterDate(detail.rgsde)}</p>
            </div>
            <div className="content" dangerouslySetInnerHTML={{ __html: trTags(detail.contents) }}></div>
          </div>
          <div>
            <div className="direction">
              {detail.prevIdx !== 0 ? (
                <div className="leftArrow">
                  <Link to={`/news/newsletter/detail/${detail.prevIdx}`} style={{ pointerEvents: detail.prevIdx === 0 ? 'none' : '' }}>
                    <span>
                      <BsArrowLeft />
                    </span>
                    <span>이전글</span>
                  </Link>
                </div>
              ) : (
                <div className="leftArrow leftArrowNone">
                  <Link to={`/news/newsletter/detail/${detail.prevIdx}`} style={{ pointerEvents: detail.prevIdx === 0 ? 'none' : '' }}>
                    <span>
                      <BsArrowLeft />
                    </span>
                    <span>이전글</span>
                  </Link>
                </div>
              )}
              <div className="centerList">
                <Link to="/news/newsLetter">
                  <div className="listIcon">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                  <p>List</p>
                </Link>
              </div>
              {detail.nextIdx !== 0 ? (
                <div className="rightArrow">
                  <Link to={`/news/newsletter/detail/${detail.nextIdx}`} style={{ pointerEvents: detail.nextIdx === 0 ? 'none' : '' }}>
                    <span>다음글</span>
                    <span>
                      <BsArrowRight />
                    </span>
                  </Link>
                </div>
              ) : (
                <div className="rightArrow rightArrowNone">
                  <Link to={`/news/newsletter/detail/${detail.nextIdx}`} style={{ pointerEvents: detail.nextIdx === 0 ? 'none' : '' }}>
                    <span>다음글</span>
                    <span>
                      <BsArrowRight />
                    </span>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default NewsLetterDetail;
