import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { Link } from 'react-router-dom';
import { SlideData } from './SlideData';
import EventPopUp from '../../popup/EventPopUp';

const Main = () => {
  const slideRef = useRef(null);
  const [IDX, setIDX] = useState();
  useEffect(() => {
    setIDX(0);
  }, []);

  const settings2 = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 830,
        settings: {
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  /*이벤트 팝업*/
  const [closePop, setClosePop] = useState(true);
  const [todayPop, setTodayPop] = useState(false);

  const onChangeToday = useCallback(
    (checked) => {
      setTodayPop(checked);
    },
    [todayPop, setTodayPop],
  );

  const VISITED_BEFORE_DATE = localStorage.getItem('VisitCookie');
  const VISITED_NOW_DATE = Math.floor(new Date().getDate());

  useEffect(() => {
    // 팝업 오늘 하루닫기 체크
    if (VISITED_BEFORE_DATE !== null) {
      // 날짜가 같을경우 노출
      if (VISITED_BEFORE_DATE === VISITED_NOW_DATE) {
        localStorage.removeItem('VisitCookie');
        setClosePop(true);
      }
      // 날짜가 다를경우 비노출
      if (VISITED_BEFORE_DATE !== VISITED_NOW_DATE) {
        setClosePop(false);
      }
    }
  }, [VISITED_BEFORE_DATE]);

  const onClickPop = useCallback(() => {
    if (todayPop) {
      const expiry = new Date();
      // +1일 계산
      const expiryDate = expiry.getDate() + 1;
      // 로컬스토리지 저장
      localStorage.setItem('VisitCookie', expiryDate);
    }
    setClosePop(false);
  }, [closePop, todayPop]);

  return (
    <>
      <Controller>
        <Scene triggerElement="#triggerPoint00" classToggle="active_mv">
          <section className="main_visual" id="triggerPoint00">
            <div className="container flex">
              <div className="title">
                <p className="topsub">이메일 보안의 New Paradigm</p>
                <h2>
                  이메일 보안에 필요한 <br />
                  모든 것을 <span className="blue">하나로!</span>
                </h2>
                <p className="subtit" id="triggerPoint01">
                  <span className="bold">EG-Platform</span> 하나로
                  <br /> 이메일을 안전하고 편리하게 사용하세요.
                </p>
              </div>
              <div className="main_image">
                <img src="/images/main_visual01.png" alt="메인비주얼 이미지" />
              </div>
              <div className="totalbtn">
                <div className="scrdwn">
                  <span className="line"></span>
                  <span className=""></span>
                  <span className="down">scroll down</span>
                </div>
              </div>
            </div>
          </section>
        </Scene>
      </Controller>
      <section className="main_01">
        <div className="container">
          <Controller>
            <Scene triggerElement="#triggerPoint01" duration={600}>
              {(progress) => (
                <Timeline totalProgress={progress} paused>
                  <Tween from={{ y: '30%', opacity: 0 }} to={{ y: '0%', opacity: 1 }}>
                    <h2>
                      단 하나의 솔루션,&nbsp;
                      <span className="blue">
                        <br className="w700" />
                        EG-Platform
                      </span>
                    </h2>
                  </Tween>
                  <Timeline>
                    <Tween from={{ y: '30%', opacity: 0 }} to={{ y: '0%', opacity: 1 }}>
                      <p className="explanation">
                        출근해서 퇴근하기 까지 늘 사용하는 이메일, 어떤 문제를 가지고 계신가요? <br />
                        EG-Platform은 <span className="bold">이메일 관련 이슈를 모두 해결</span>해 줄 <br className="w800" />
                        뿐만 아니라
                        <span className="bold"> 보안 그 이상의 가치, 사용자의 경험</span>까지 추구합니다.
                      </p>
                    </Tween>
                  </Timeline>
                </Timeline>
              )}
            </Scene>
          </Controller>
          <ul className="link_box flex" id="triggerPoint02">
            <li className="inbound">
              <Link to="/product-composition/receiving-security">
                <div className="box_tit">
                  <h3>수신보안</h3>
                  <span>Inbound Email Security</span>
                  <p>
                    능동적으로 보안 영역 전 구간을 검사하고,
                    <br className="w1200" />
                    선제대응이 가능한 강력한 수신메일 보안 기술
                  </p>
                </div>
              </Link>
            </li>
            <li className="outbound">
              <Link to="/product-composition/outgoing-security">
                <div className="box_tit">
                  <h3>발신보안</h3>
                  <span>Outbound Email Security </span>
                  <p>
                    발송된 이메일과 첨부파일을 제어 및 추적 관리
                    <br className="w1200" />
                    하는 안전하고 편리한 발신메일 관리
                  </p>
                </div>
              </Link>
            </li>
            <li className="network">
              <Link to="/product-composition/EG-Linker">
                <div className="box_tit">
                  <h3>EML 재조합</h3>
                  <span>Email Gateway Linker</span>
                  <p>
                    망분리 환경에서도 중단 및 누락 없는 원활한
                    <br className="w1200" />
                    메일 수·발신, 망분리 규정 준수를 위한 기술
                  </p>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </section>
      <section className="main_02">
        <Controller>
          <Scene triggerElement="#triggerPoint02" duration={700}>
            {(progress) => (
              <>
                <Timeline totalProgress={progress} paused>
                  <Tween from={{ y: '20%', opacity: 0 }} to={{ y: '0%', opacity: 1 }}>
                    <div className="w1200" id="triggerPoint03">
                      <p>
                        정상메일로 보이는 사기메일,
                        <br className="w505" /> 회수할 수 없는 메일과 첨부파일
                      </p>
                      <h2>
                        <span className="bold">이메일</span>에 대한&nbsp;
                        <span className="boldblue">
                          모든 해결책을 <br className="w800" />
                          EG-Platform <br className="dis-n_m" />
                          <span className="dotted">하</span>
                          <span className="dotted">나</span>에 담았습니다.
                        </span>
                      </h2>
                      <p>
                        "수신메일을 안전하게 열람하고, <br className="w630" />
                        발신메일을 편리하게 관리하세요."
                      </p>
                    </div>
                  </Tween>
                </Timeline>
              </>
            )}
          </Scene>
        </Controller>
        <Controller>
          <Scene triggerElement="#triggerPoint02" duration={1500}>
            {(progress) => (
              <div className="w1300 flex">
                <div className="img">
                  <img src="/images/main_02_number.png" alt="" className="num_pc" />
                  <img src="/images/main_02_number_400.png" alt="" className="num_m" />
                </div>
                <ul className="paragraph">
                  <Timeline totalProgress={progress} paused>
                    <Tween from={{ y: '20%', opacity: 0 }} to={{ y: '0%', opacity: 1 }}>
                      <li>
                        <h3>
                          오직 <span className="boldblue">하나</span>뿐인
                          <br /> 진짜 사기메일 필터
                        </h3>
                        <p>
                          단순 DB 비교 방식이 아닌, <br className="w1300" /> EG-Platform만의
                          <br className="w800" /> ‘실시간, 동적, 학습‘ 기반 발신자 검사를 실시합니다. <br />
                          백신으로 방지 불가한 거래처 사기메일을
                          <br className="w800" /> 실시간으로 차단합니다.
                        </p>
                      </li>
                    </Tween>
                    <Timeline>
                      <Tween from={{ y: '20%', opacity: 0 }} to={{ y: '0%', opacity: 1 }}>
                        <li>
                          <h3>
                            이메일 보안에 필요한
                            <br /> 모든 것을 <span className="boldblue">하나</span>로
                          </h3>
                          <p>
                            수신부터 발신까지 전 구간 보안. <br />
                            능동적인 선제대응이 가능합니다. 스팸메일부터 바이러스, 피싱사이트, 신종 랜섬웨어, <br className="w800" /> 정상메일로 위장한
                            사기메일까지, 모든 공격 유형에 대해
                          </p>
                        </li>
                      </Tween>
                    </Timeline>
                    <Timeline>
                      <Tween from={{ y: '20%', opacity: 0 }} to={{ y: '0%', opacity: 1 }}>
                        <li id="trigger3">
                          <h3>
                            보안에 편리함을 더한
                            <br /> <span className="boldblue">하나</span>의 업무 플랫폼
                          </h3>
                          <p>
                            전 구간 보안 영역의 관리포인트를 간편하게 하나로. <br />
                            안전한 메일만 수신하고, 이미 발신한 메일과 첨부파일을 회수하고, <br className="w800" /> 망분리 환경에서도 메일을 편리하게
                            사용하세요.
                          </p>
                        </li>
                      </Tween>
                    </Timeline>
                  </Timeline>
                </ul>
              </div>
            )}
          </Scene>
        </Controller>
      </section>
      <section className="main_03">
        <Controller>
          <Scene triggerElement="#trigger3" duration={800}>
            {(progress) => (
              <div className="main04_inner">
                <Timeline totalProgress={progress} paused>
                  <Tween from={{ y: '20%', opacity: 0 }} to={{ y: '0%', opacity: 1 }}>
                    <div className="toptit flex">
                      <h2>
                        이메일을 제일 잘 아는 <span className="bold">전문가</span>가 만든 <br className="w500" />
                        <span className="bold">단 하나뿐인 Email Security Solution</span>
                        <span className="none">입니다</span>.
                      </h2>
                      <figure>
                        <img src="/images/no1.png" alt="넘버원" />
                      </figure>
                    </div>
                  </Tween>
                  <Timeline>
                    <Tween from={{ y: '30%', opacity: 0 }} to={{ y: '0%', opacity: 1 }}>
                      <ul className="gap50 flex">
                        <li>
                          <div>
                            <p>
                              국내 유일&nbsp;
                              <br className="w1300" />
                              <br className="w520" />
                              <span className="highlighter">
                                <span className="bold"> Gartner</span> 보고서 등재
                              </span>
                            </p>
                            <p id="trigger4">
                              머신러닝과 AI기반으로 <br className="w1300" />
                              선제 대응하여, <br className="w800" />
                              지역을 대표하는 <br className="w1300" /> 이메일 보안 Vendor로 선정
                            </p>
                          </div>
                        </li>
                        <li>
                          <p>
                            표적형 이메일 공격 차단&nbsp;
                            <br className="w1300" />
                            <br className="w520" />
                            <span className="highlighter">국내 보안 표준으로 채택</span>
                          </p>
                          <p>
                            이메일 보안에 대해 <br className="w1300" />
                            가장 잘 아는 전문가로서 <br /> 보안 기능의 Standard를 제시
                          </p>
                        </li>
                        <li>
                          <p id="triggerPoint04">
                            국내 최다 30건
                            <br className="w1300" />
                            메일 관련 <br className="w520" />
                            <span className="highlighter"> 국내·외 특허 보유</span>
                          </p>
                          <p>
                            이메일 망연계 기술 포함, <br className="w1300" />
                            차세대 이메일 보안 기술과
                            <br className="w800" /> 다양한 <br className="w1300" /> 메일 편의 기능을 가장 많이 보유
                          </p>
                        </li>
                      </ul>
                    </Tween>
                  </Timeline>
                </Timeline>
              </div>
            )}
          </Scene>
        </Controller>
      </section>
      <section className="main_04">
        <div className="w13000">
          <div className="w1200">
            <Controller>
              <Scene triggerElement="#triggerPoint04" duration={800}>
                {(progress) => (
                  <Timeline totalProgress={progress} paused>
                    <Tween from={{ y: '20%', opacity: 0 }} to={{ y: '0%', opacity: 1 }}>
                      <div className="tit">
                        <p>안전하고 편리하게 사용할 수 있는 이메일 보안 솔루션,</p>
                        <h2>
                          EG-Platform <span className="boldblue">하나로 </span>해결하세요!
                        </h2>
                      </div>
                    </Tween>
                  </Timeline>
                )}
              </Scene>
            </Controller>
            <div className="slideArrow flex">
              <button className="arrow_left" onClick={() => slideRef.current.slickPrev()}></button>
              <button className="arrow_right" onClick={() => slideRef.current.slickNext()}></button>
            </div>
          </div>
          <div className="slide_box">
            <Slider {...settings2} dotsClass="customDots" ref={slideRef}>
              {SlideData.map((it) => {
                return (
                  <ul className="hover_desc" key={it.id}>
                    <li>
                      <span>{it.case}</span>
                      <h3>{it.comment}</h3>
                      <p>{it.datail}</p>
                    </li>
                  </ul>
                );
              })}
            </Slider>
          </div>
        </div>
      </section>
      <section className="main_05">
        <div className="w1200">
          <p>
            이메일 보안 솔루션,
            <br />
            EG-Platform에 대해 궁금하신가요?
          </p>
          <div className="contact">
            <Link to="/customer/product-inquiry/contact-us" className="flex">
              <span>Contact us</span>
              <figure>
                <img src="/images/icon/contact_arrow.png" alt="" />
              </figure>
            </Link>
          </div>
        </div>
                {closePop && <EventPopUp onClickPop={onClickPop} onChangeToday={onChangeToday} />}
      </section>
    </>
  );
};

export default Main;
