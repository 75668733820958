import React, { useEffect } from 'react';
import { BsXLg } from 'react-icons/bs';

const ConfirmToPartner_ = ({ setDownloadConfirm }) => {
  useEffect(() => {
    document.body.style.cssText = `
    position: fixed; 
    top: -${window.scrollY}px;
    overflow-y: scroll;
    width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    };
  }, []);

  return (
    <div className="modalWrap">
      <div className="modalBody appBox">
        <div className="modTitle">
          <h2>제휴문의 접수 완료</h2>
        </div>
        <div className="salutation">
          <p>
            문의 내용은 담당자 확인 후,
            <br />
            기입하신 연락처로 답변 드리겠습니다.
            <br />
            감사합니다.
          </p>
          <button
            onClick={() => {
              setDownloadConfirm(false);
            }}
          >
            확인
          </button>
        </div>
        <div
          className="closeBtn positionAdj"
          onClick={() => {
            setDownloadConfirm(false);
          }}
        >
          <button>
            <BsXLg />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmToPartner_;
